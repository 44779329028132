import { IResultApi } from '@/interfaces/IResultApi';
import { http } from '@/request/index';
import app  from "@/main";

export default {
    addAtendimento(parametros: {}) {
      return http.post<IResultApi>(`callCenter/atendimento`, parametros);
    },

    finalizeAtendimento(atendimentoId: number, tipoFinalizacao: number){
      http.post<IResultApi>(`callCenter/finalizeatendimento?atendimentoId=${atendimentoId}&tipoFinalizacao=${tipoFinalizacao}`);
      app.$store.commit('clearAtendimento');
    }
  }