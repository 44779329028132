
import { Component, Vue } from "vue-property-decorator";
import SolicitacaoDeTransferencia from "../components/SolicitacaoDeTransferencia.vue";

@Component({
  components: {
    SolicitacaoDeTransferencia
  },
})
export default class Transferencia extends Vue {

}
