import store from '@/store';
import axios from 'axios';

const urlCentralDeNotificacoes = process.env.VUE_APP_API_NOTIFICACAO;
const cacheUrl = process.env.VUE_APP_API_CACHE;
const baseUrl = process.env.VUE_APP_API_PRINCIPAL;

const http = axios.create({ baseURL: baseUrl });
const cacheHttp = axios.create({ baseURL: cacheUrl });
const notificacao = axios.create({ baseURL: urlCentralDeNotificacoes });

const setBearerToken = (token: string, tokenNotificacao: string) => {
  http.defaults.headers.Authorization = `Bearer ${token}`;
  cacheHttp.defaults.headers.Authorization = `Bearer ${token}`;
  notificacao.defaults.headers.Authorization = `Bearer ${tokenNotificacao}`;

  store.state.dataHoraToken = new Date();
};

const getBearerToken = () => {
  return http.defaults.headers.Authorization;
}

const getUrl = () => {
  return baseUrl;
}

const getUrlCentralDeNotificacoes = () => {
  return `${urlCentralDeNotificacoes}/hub/FilaMatriculaOnline`;
}

const getUrlCache = () => {
  return cacheUrl;
}

export default { http, cacheHttp }
export 
{ 
  http, notificacao, cacheHttp, 
  setBearerToken, getBearerToken, getUrl, getUrlCentralDeNotificacoes, getUrlCache
};