
import { alertWarning } from '@/utils/functions';
import { Component, Vue } from "vue-property-decorator";
import candidatoService from '@/services/candidatoService';
import { base64ToArrayBuffer, saveByteArray } from "../utils/functions";
import localStorageService from '@/services/localStorageService';
import { eTipoPesquisaProtocolo, PesquisaProtocolo } from '@/enums/eTipoPesquisaProtocolo';

@Component
export default class ModalConsultaProtocolo extends Vue {
  loading = false;
  texto = "";
  tipoDePesquisa: eTipoPesquisaProtocolo | null;
  tipos = [
    eTipoPesquisaProtocolo.MatriculaOnline,
    eTipoPesquisaProtocolo.CadastroAntecipado,
    eTipoPesquisaProtocolo.SolicitacaoDeTransferencia
  ];
  valid = false;
  dialog = false;

  textoRules = [
    (v: string) => !!v || "Informe um Protocolo, Cartão SUS ou CPF"
  ];

  regrasTipoDePesquisa = [
    (v: eTipoPesquisaProtocolo) => !!v || 'Informe o tipo de pesquisa desejado.'
  ]

  constructor() {
    super();
    
    this.tipoDePesquisa = null;
    this.texto = '';
  }
  
  openModal(): void {
    this.dialog = true;
    this.tipoDePesquisa = null;
    this.texto = '';
  }

  obtenhaCorPrimaria(){
    return localStorageService.obtenhaConfiguracaoGeral().corPrimaria;
  }

  async consulta() {
    const validacao = (this.$refs.form as Vue & {
      validate: () => boolean;
    }).validate();

    if (validacao) {
      const texto = this.texto.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
      candidatoService.getProtocolo(this.tipoDePesquisa!, texto);
    }
  }

  DescricaoDoTipoDePesquisaDeProtocolo(item: eTipoPesquisaProtocolo) {
    return PesquisaProtocolo.get(item);
  }
}
