import { CodigoDescricaoDTO } from "./CodigoDescricaoDTO";

export class EscolaPrefencialDTO {
    public ordem!: number;
    public codigo!: number | null;
    public descricao!: string;
    public turno!: number | null;
    public turnosParaSelecao!: CodigoDescricaoDTO[];
    constructor(index: number) {
        this.ordem = index;
    }
}