
import { ConfiguracaoGeral } from '@/models/ConfiguracaoGeral';
import localStorageService from '@/services/localStorageService';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Countdown extends Vue {
  configuracaoGeral!: ConfiguracaoGeral;
  start!: Date;
  final!: Date;
  msg = localStorage.getItem('msg');
  constructor() {
    super();
    const stringIni = localStorage.getItem('dtInicio');
    const stringFim = localStorage.getItem('dtFim');
    if (stringIni && stringFim) {
      this.start = new Date(stringIni);
      this.final = new Date(stringFim);
    }

    this.configuracaoGeral = localStorageService.obtenhaConfiguracaoGeral();
  }

  endCallBack() {
    this.$router.push('/');
  }

  obtenhaLogo() {
    return this.configuracaoGeral.brasao;
  }

  obtenhaCorDeFundo() {
    return this.configuracaoGeral.corPrimaria;
  }
}
