
import { IParametros } from "@/interfaces/IParametros";
import { Solicitante } from "@/models/Solicitante";
import { Component, Vue } from "vue-property-decorator";
import { colors } from "vuetify/lib";
import { eParentesco, ParentescoLabel } from "../enums/eParentesco";
import { validaCpf, alertError, validaCelular } from "../utils/functions";
import callCenterService from "../services/callCenterService";
import { Atendimento } from "@/models/Atendimento";
import localStorageService from "@/services/localStorageService";

@Component
export default class ModalSolicitante extends Vue {
  
  parametros: IParametros = this.$store.state.parametros;
  valid = false;
  dialog = false;
  novoSolicitante = true;
  ehReadOnly = false;
  loading = false;

  itemsParentesco = [
    eParentesco.Mae,
    eParentesco.Pai,
    eParentesco.Tio,
    eParentesco.Primo,
    eParentesco.Avo,
    eParentesco.Cunhado,
    eParentesco.Irmao,
    eParentesco.Madrasta,
    eParentesco.Padrasto,
    eParentesco.Outro,
  ];
  itemsTrabalha = [false, true];
  dataNascimento = "";

  solicitante: Solicitante;
  
  constructor(){
    super();
    if(this.$store.state.atendimento.id){
      this.solicitante = this.$store.state.solicitante as Solicitante;
      this.novoSolicitante = false;
      this.ehReadOnly = true;
    }
    else{
      this.solicitante = new Solicitante();
      this.solicitante.ehOProprioCandidato = false;
    }
  }

  validate = {
    nome: [
      (v: string) => !!v || !!v?.trim() || "Nome é obrigatório",
      (v: string) => (v && v.length <= 300) || "Nome deve ter no máximo 300 caracteres",
    ],
    celular: [
      (v: string) => !!v || "Telefone é obrigatório",
      (v: string) => validaCelular(v) || "O telefone informado é inválido",
    ],
    parentesco: [
      (v: number) =>
        !!v || v == 0 || this.solicitante.ehOProprioCandidato || "Escolha o nível de parentesco do solicitante",
    ],
    cpf: [
      //(v: string) => this.getRulesCpf(v) || "CPF é obrigatório",
      (v: string) => validaCpf(v) || "CPF inválido",
    ],
    //renda: [(v: string) => this.getRulesRenda(v) || "Renda é obrigatório"],
    //trabalha: [(v: boolean) => v != undefined || "Responsável trabalha?"],
    email: [
      (v: string) =>
        !v || v.length <= 150 || "E-mail não pode ter mais de 150 caracteres",
      (v: string) => !v || /.+@.+/.test(v) || "E-mail inválido",
    ],
    /*data: [
      (v: string) => validaData(v) && !!v || !v || "Data de nascimento inválida",
    ],*/
    telefoneSecundario: [
      //(v: string) => !!v || "Celular é obrigatório",
      (v: string) => validaCelular(v) || "O telefone informado é inválido",
    ],
    outroParentesco: [
      (v: string) => !this.parentescoEhOutro() || !!v || "Obrigatório informar o vínculo quando o parentesco for Outro",
      (v: string) => !v || v.length <= 50 || "Vínculo não pode ter mais de 50 caracteres"
    ]
  };


  openModalVazio(): void {
    this.solicitante = new Solicitante();
    this.solicitante.ehOProprioCandidato = false;
    this.solicitante.ehResponsavelDoCandidato = false;
    this.novoSolicitante = true;
    this.ehReadOnly = false;
    
    this.openModal();
  }

  openModal(): void {

    this.dialog = true;
  }

  corBackground(){
    if(!this.novoSolicitante) return colors.grey.lighten2;
    return '';
  }

  corBackgroundParentesco(){
    if(this.solicitante.ehOProprioCandidato) return colors.grey.lighten2;
    return '';
  }

  salvar(): void {
    const validacao = (this.$refs.form as Vue & {
      validate: () => boolean;
    }).validate();
    if (validacao) {
      this.loading = true;
      this.$store.commit("setSolicitante", this.solicitante); 
      this.crieAtendimento();
    }
  }

  async crieAtendimento() {
    if(!this.$store.state.atendimento.id){
      const usuarioId = localStorageService.getItem('usuarioid');
      if(!usuarioId){
        alertError("Não foi possível obter o operador logado, por favor atualize a página");
        return;
      }

      const atendimento = new Atendimento();
      atendimento.UsuarioId = usuarioId;
      atendimento.Solicitante = this.solicitante;
      
      await callCenterService
      .addAtendimento(atendimento)
      .then((response) => {
          const result = response.data;
          if (result.sucesso) {
            atendimento.id = result.dados.atendimentoId;
            this.$store.commit("setAtendimento", atendimento);
            
            this.loading = false;
            this.dialog = false;
          }
          else{
            this.loading = false;
            alertError(result.mensagem);
          }
      })
      .catch(() => {
        alertError("Não foi possível iniciar o atendimento, favor entrar em contato com o suporte.");
        this.loading = false;
      });
    }
    else this.dialog = false;
    this.loading = false;
  }

  getParentescoDescription(item: eParentesco) {
    return ParentescoLabel.get(item);
  }

  escondeCampo(campo: string): boolean {
    switch (campo) {
      case 'parentesco':
        return this.solicitante.ehOProprioCandidato;
      case 'ehResponsavelDoCandidato':
        return this.solicitante.ehOProprioCandidato;

      default:
        return true;
    }
  }

  parentescoEhOutro(): boolean {
    return this.solicitante.parentesco == eParentesco.Outro;
  }

  changeEhOProprioCandidato(){
    if(this.solicitante.ehOProprioCandidato){
      this.solicitante.parentesco = null;
      this.solicitante.ehResponsavelDoCandidato = null;
    }
  }

  changeParentesco(){
    if(this.solicitante.parentesco == eParentesco.Outro){
      this.solicitante.ehResponsavelDoCandidato = null;
    }
  }
}
