import { CodigoDescricaoDTO } from "./CodigoDescricaoDTO";

export class DadosSocioeconomicosDTO {
    public rendaFamiliar!: Array<CodigoDescricaoDTO>;
    public tiposDeMoradia!: Array<CodigoDescricaoDTO>;
    public situacoesDeMoradia!: Array<CodigoDescricaoDTO>;
    public dependenciasFisicas!: Array<CodigoDescricaoDTO>;
    public tipoAbastecimentoDeAgua!: Array<CodigoDescricaoDTO>;
    public dispositivosDeAcessoInternet!: Array<CodigoDescricaoDTO>;
    public fontesDeEnergia!: Array<CodigoDescricaoDTO>;
    public destinacaoDoLixo!: Array<CodigoDescricaoDTO>;

    constructor() {
        this.rendaFamiliar = new Array<CodigoDescricaoDTO>();
        this.tiposDeMoradia = new Array<CodigoDescricaoDTO>();
        this.situacoesDeMoradia = new Array<CodigoDescricaoDTO>();
        this.dependenciasFisicas = new Array<CodigoDescricaoDTO>();
        this.tipoAbastecimentoDeAgua = new Array<CodigoDescricaoDTO>();
        this.dispositivosDeAcessoInternet = new Array<CodigoDescricaoDTO>();
        this.fontesDeEnergia = new Array<CodigoDescricaoDTO>();
        this.destinacaoDoLixo = new Array<CodigoDescricaoDTO>();
    }
}