import { eTurno } from '@/enums/eTurno';

export class ConfiguracaoSeriePreMatriculaDTO {
  public id!: number;
  public serieId!: number;
  public serieNome!: string;
  public turnos!: eTurno[];
  public candidatoPodeSerCadastrado!: boolean;
  public idadeMinima!: number;
  public dataDeCorte!: string;
}