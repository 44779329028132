import { CadastroAntecipadoDTO } from "@/dtos/CadastroAntecipadoDTO";
import { ConfiguracaoGeral } from "@/models/ConfiguracaoGeral";
import { ProcessamentoProtocolo } from "@/models/ProcessamentoProtocolo";
import { Propriedades } from "@/models/Propriedades";

const CHAVE_PROTOCOLO = 'protocolos_fila_espera';
const CHAVE_CONFIGURACAO_GERAL = 'configuracaoGeral';
const CHAVE_PROPRIEDADES_MATRICULA = 'propriedades_matricula';
const CHAVE_CADASTRO_ANTECIPADO = 'cadastro_antecipado';
const CHAVE_DATA_INICIO = 'dtInicio';
const CHAVE_DATA_FIM = 'dtFim';
const CHAVE_MENSAGEM = 'msg';
const CHAVE_USUARIOID = 'usuarioid';

export default {
  setDataInicio(inicio: string) {
    localStorage.setItem(CHAVE_DATA_INICIO, inicio);
  },
  setDataFim(fim: string) {
    localStorage.setItem(CHAVE_DATA_FIM, fim);
  },
  getItem(item: string) {
    return localStorage.getItem(item);
  },
  setConfiguracaogeral(configuracao: ConfiguracaoGeral) {
    localStorage.setItem(CHAVE_CONFIGURACAO_GERAL, JSON.stringify(configuracao));
  },
  obtenhaConfiguracaoGeral(): ConfiguracaoGeral{
    const stringConfiguracao = localStorage.getItem(CHAVE_CONFIGURACAO_GERAL) || '';
    return Object.assign(new ConfiguracaoGeral(), JSON.parse(stringConfiguracao ?? ''));
  },
  setMensagemAguarde(msg: string) {
    localStorage.setItem(CHAVE_MENSAGEM, msg);
  },
  setUsuarioId(id: string){
    localStorage.setItem(CHAVE_USUARIOID, id);
  },
  atualizePropriedades(propriedades: Propriedades) {
    this.limpePropriedades();
    localStorage.setItem(CHAVE_PROPRIEDADES_MATRICULA, JSON.stringify(propriedades));
  },
  obtenhaPropriedades() {
    return JSON.parse(localStorage.getItem(CHAVE_PROPRIEDADES_MATRICULA) || '');
  },
  limpePropriedades() {
    localStorage.removeItem(CHAVE_PROPRIEDADES_MATRICULA);
  },
  atualizeDadosDoCadastroAntecipado(dados: CadastroAntecipadoDTO){
    localStorage.setItem(CHAVE_CADASTRO_ANTECIPADO, JSON.stringify(dados));
  },
  obtenhaDadosDoCadastroAntecipado() {
    return JSON.parse(localStorage.getItem(CHAVE_CADASTRO_ANTECIPADO) || '');
  },
  limpeDadosDoCadastroAntecipado() {
    localStorage.removeItem(CHAVE_CADASTRO_ANTECIPADO);
  },
  obtenhaProtocolos() {
    return Object.assign(new Array<ProcessamentoProtocolo>(), JSON.parse(localStorage.getItem(CHAVE_PROTOCOLO) ?? '{}'));
  },
  adicioneProtocolo(protocolo: ProcessamentoProtocolo) {
    const protocolos = this.obtenhaProtocolos();
    if (protocolos) {
      const protocoloJaExistente = protocolos.filter(c => c.Protocolo == protocolo.Protocolo);
      if (protocoloJaExistente.length == 0) {
        protocolos.push(protocolo);
      } else {
        protocoloJaExistente[0].Sucesso = protocolo.Sucesso;
      }
      localStorage.removeItem(CHAVE_PROTOCOLO);
      localStorage.setItem(CHAVE_PROTOCOLO, JSON.stringify(protocolos));
    }
  },
  removaProtocolo(protocolo: string) {
    const protocolos = this.obtenhaProtocolos();
    const protocoloExistente = protocolos.filter(c => c.Protocolo == protocolo);
    if (protocoloExistente.length > 0) {
      const index = protocolos.indexOf(protocoloExistente[0]);
      if (index !== -1) {
        protocolos.splice(index, 1);
      }

      localStorage.removeItem(CHAVE_PROTOCOLO);
      localStorage.setItem(CHAVE_PROTOCOLO, JSON.stringify(protocolos));
    }
  }
}