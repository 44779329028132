import { CodigoDescricaoDTO } from '@/dtos/CodigoDescricaoDTO';
import { cacheHttp } from '@/request/index';

export function getAlimentos() {
    return cacheHttp.get(`Alimentos`);
}

export function getDoencas() {
    return cacheHttp.get<CodigoDescricaoDTO[]>(`Doencas`);
}

export function getMedicamentos() {
    return cacheHttp.get(`Medicamentos`);
}

export function getAtividadesFisicas() {
    return cacheHttp.get(`AtividadesFisicas`);
}