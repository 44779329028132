
import FilaDeEspera from "@/components/FilaDeEspera.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    FilaDeEspera
  },
})
export default class ConsultaFilaDeEspera extends Vue {
  
}

