import { CodigoDescricaoDTO } from "@/dtos/CodigoDescricaoDTO";
import { eParentesco } from "@/enums/eParentesco";
import { eSexo } from "@/enums/eSexo";
import { eTipoDeTrabalhoMae } from "@/enums/eTipoDeTrabalhoMae";
import { eZona } from "@/enums/eZona";
import { Documento } from "./Documento";
import { Solicitante } from "./Solicitante";

export class CandidatoCadastroAntecipado {
    public id!: number | null;

    public nome = '';
    public sexo!: eSexo;
    public dataNascimento = '';
    public cpf = '';
    public cartaoSus = '';
    public nis = '';
    public telefonePrincipal = '';
    public telefoneSecundario = '';
    public rendaFamiliar!: CodigoDescricaoDTO;
    public tipoDaMoradia!: CodigoDescricaoDTO;
    public situacaoDaMoradia!: CodigoDescricaoDTO;
    public quantidadeDePessoasQueMoramNaMesmaCasa!: number;
    public dependenciasFisicas!: CodigoDescricaoDTO[];
    public tipoDeAbastecimentoDeAgua!: CodigoDescricaoDTO[];
    public fonteDeEnergiaEletrica!: CodigoDescricaoDTO[];
    public quantidadeDePessoasQueCompoemRendaFamiliar!: number;
    public dispositivosDeAcessoInternet!: CodigoDescricaoDTO[];
    public destinoDoLixo!: CodigoDescricaoDTO[];
    public possuiDeficiencia = false;
    public possuiIrmaoGemeo = false;

    public cep = '';
    public logradouro = '';
    public bairro = '';
    public complemento = '';
    public numero = '';
    public pontoReferencia = '';
    public cidade!: CodigoDescricaoDTO;
    public estado = '';
    public zonaResidencia!: eZona;

    public responsavelNome = '';
    public responsavelTelefonePrincipal = '';
    public responsavelTelefoneSecundario = '';
    public responsavelCpf = '';
    public responsavelParentesco!: eParentesco;
    public responsavelTipoDeTrabalhoMae!: eTipoDeTrabalhoMae | null;

    public documentos!: Documento[];

    public usuarioId = '';
    public atendimentoCallCenterId = '';
    public solicitante!: Solicitante;
}