export enum eTipoPesquisaProtocolo {
    MatriculaOnline = 1,
    CadastroAntecipado = 2,
    SolicitacaoDeTransferencia = 3
}

export const PesquisaProtocolo = new Map<number, string>([
    [eTipoPesquisaProtocolo.MatriculaOnline, 'Matrícula Online'],
    [eTipoPesquisaProtocolo.CadastroAntecipado, 'Cadastro Antecipado'],
    [eTipoPesquisaProtocolo.SolicitacaoDeTransferencia, 'Solicitação de Transferência']
]);