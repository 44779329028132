import { CodigoDescricaoDTO } from '@/dtos/CodigoDescricaoDTO';
import { EscolaPrefencialDTO } from '@/dtos/EscolaPreferencialDTO';
import { EscolaPreMatriculaDTO } from '@/dtos/EscolaPreMatriculaDTO';
import { eCorRaca } from '@/enums/eCorRaca';
import { eSexo } from '@/enums/eSexo';
import { eTurno } from '@/enums/eTurno';
import { Deficiencia } from './Deficiencia';
import { Responsavel } from './Responsavel';
import { eZona } from '@/enums/eZona';

export class Aluno {
    public nome = '';
    public cartaoSus = '';
    public cpf = '';
    public sexo!: eSexo;
    public corRaca!: eCorRaca;
    public dataNascimento!: Date;
    public deficiencia!: Deficiencia;
    public cep!: string;
    public zonaResidencia!: eZona;
    public logradouro!: string;
    public complemento = '';
    public numero =  '';
    public bairro!: string;
    public pontoReferencia = '';
    public estado!: string;
    public cidade!: CodigoDescricaoDTO;
    public responsaveis!: Responsavel[];
    public observacao = '';
    public serieId!: number | null;
    public escolaIdIrmao!: EscolaPreMatriculaDTO;
    public escolaEntregaDeDocumentos!: number;
    public turno!: eTurno;
    public configuracaoSerieId!: number;
    public tempoResidencia = '';
    public numeroNIS = '';
    public maeGestante = false;
    public ehEstudante = false;
    public escolaPreferencial!: Array<EscolaPrefencialDTO>;
    public alunoId!: number;
    public escolaOrigem!: string;
    public celularPrincipal!: string;
    public celularSecundario!: string;
    public deficienciaDescricao!: string;
    public exibirOutraDeficiencia!: boolean;
    public deficienciasDoAluno!: Deficiencia[];
    public doencas!: number[];
    public alimentos!: number[];
    public atividadesFisicas!: number[];
    public medicamentosEmUso!: number[];
    public unidadeConsumidora = '';
    public cartaoSusIrmao = '';
    public cpfIrmao = '';
    public possuiIrmao = false;
    public necessitaDeTransporteEscolar!: boolean | null;
    public escolaParaContemplacaoDireta!: EscolaPreMatriculaDTO;
    public munipioOndeDesejaEstudar!: CodigoDescricaoDTO;

    public rendaFamiliar!: CodigoDescricaoDTO;
    public tipoDaMoradia!: CodigoDescricaoDTO;
    public situacaoDaMoradia!: CodigoDescricaoDTO;
    public quantidadeDePessoasQueMoramNaMesmaCasa!: number;
    public dependenciasFisicas!: CodigoDescricaoDTO[];
    public tipoDeAbastecimentoDeAgua!: CodigoDescricaoDTO[];
    public fonteDeEnergiaEletrica!: CodigoDescricaoDTO[];
    public quantidadeDePessoasQueCompoemRendaFamiliar!: number;
    public dispositivosDeAcessoInternet!: CodigoDescricaoDTO[];
    public destinoDoLixo!: CodigoDescricaoDTO[];

    public candidatoDoCadastroAntecipadoId!: number | null;

    public beneficiarioDoProgramaAuxilioBrasil!: boolean;
}