import { eSituacaoCandidato } from '@/enums/eSituacaoCandidato';

export class Candidato {
  public protocolo!: string;
  public parentescoResponsavel!: string;
  public dataCadastro!: string;
  public dataNascimento!: string;
  public nome!: string;
  public nomeResponsavel!: string;
  public escolaEntrega!: string;
  public serie!: string;
  public ehInfantil!: boolean;
  public maeGestante!: boolean;
  public situacao!: eSituacaoCandidato;
}