export enum eParentesco {
    Mae,
    Pai,
    Tio,
    Primo,
    Avo,
    Cunhado,
    Irmao,
    Madrasta,
    Padrasto,    
    Outro
}

export const ParentescoLabel = new Map<number, string>([
    [eParentesco.Mae, 'Mãe'],
    [eParentesco.Pai, 'Pai'],
    [eParentesco.Tio, 'Tio(a)'],
    [eParentesco.Primo, 'Primo(a)'],
    [eParentesco.Avo, 'Avô(ó)'],
    [eParentesco.Cunhado, 'Cunhado(a)'],
    [eParentesco.Irmao, 'Irmão(ã)'],
    [eParentesco.Madrasta, 'Madrasta'],
    [eParentesco.Padrasto, 'Padrasto'],
    [eParentesco.Outro, 'Outro']
]);