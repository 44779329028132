import { eTurno } from "@/enums/eTurno";
import { CodigoDescricaoDTO } from "./CodigoDescricaoDTO";

export class AlunoSolicitacaoDeTransferenciaDTO {
    public alunoId!: number;
    public nome = '';
    public dataDeNascimento = '';
    public escolaDeOrigemId!: number;
    public escolaDeOrigem = '';
    public turnoDeOrigem = '';
    public serieDeDestino!: CodigoDescricaoDTO;
    public turnoDeDestino!: eTurno | null;
    public escolaDeDestino!: CodigoDescricaoDTO | null;
}