export enum eTurno {
  Matutino = 0,
  Vespertino = 1,
  Noturno = 2,
  Integral = 3,
  Intermediario = 4,
  Diurno = 5
}

export const TodosOsTurnos = [
  eTurno.Matutino,
  eTurno.Vespertino,
  eTurno.Noturno,
  eTurno.Integral,
  eTurno.Intermediario,
  eTurno.Diurno
]
  
export const TurnoLabel = new Map<number, string>([
  [eTurno.Matutino, 'Matutino/Manhã'],
  [eTurno.Vespertino, 'Vespertino/Tarde'],
  [eTurno.Noturno, 'Noturno/Noite'],
  [eTurno.Integral, 'Integral'],
  [eTurno.Intermediario, 'Intermediário'],
  [eTurno.Diurno, 'Diurno'],
]);
  