
import { eParentesco, ParentescoLabel } from "@/enums/eParentesco";
import { Component, Vue } from "vue-property-decorator";
import { Responsavel } from "../models/Responsavel";
import { confirm } from '@/utils/functions';

@Component
export default class TableResponsaveis extends Vue {
  responsaveis: Responsavel[] = this.$store.state.responsaveis;

  getParentescoDescription(item: eParentesco) {
    return ParentescoLabel.get(item);
  }

  removeResponsavel(item: Responsavel) {
    confirm(
      "Remover Responsável",
      `Deseja realmente remover o responsável ${item.nome}?`
    ).then((result) => {
      if (result.isConfirmed) {
        this.$store.commit("removeResponsavel", item);
      }
    });
  }
}
