import { eTipoPesquisaProtocolo } from '@/enums/eTipoPesquisaProtocolo';
import { eTurno } from '@/enums/eTurno';
import { IResultApi } from '@/interfaces/IResultApi';
import { http } from '@/request/index';
import { alertSuccess, alertWarning, base64ToArrayBuffer, closeLoading, confirm, saveByteArray, showLoading } from '@/utils/functions';
import localStorageService from './localStorageService';
import { ProcessamentoProtocolo } from '@/models/ProcessamentoProtocolo';

export default {
  addCandidato(parametros: {}, file: File | null) {
    const formData = new FormData();
    if (file) formData.append('file', file);
    formData.append('candidato', JSON.stringify(parametros));

    return http.post<IResultApi>(`/candidato`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
  },
  validaCandidato(parametros: {}) {
    const url = `candidato/valida`;
    return http.post<IResultApi>(url, parametros);
  },
  getProtocolo(tipo: eTipoPesquisaProtocolo, texto: string) {
    showLoading('Aguarde...');
    const url = `/protocolo/busca/${tipo}/${texto}`;
    setTimeout(() => {
      http.get<IResultApi>(url, { headers: { 'Accept': 'application/pdf', }, })
      .then(res => {
        closeLoading();
        if (res.data.sucesso) {
          // Se o protocolo for do tipo 'Matricula Online' e estiver aguardando processamento, ele será adicionado na lista de protocolos em espera.
          const dados = res.data.dados;
          if (dados.length > 0) {
            dados.forEach(protocolo => {
              if (protocolo.estaNaFila) {
                localStorageService.adicioneProtocolo(new ProcessamentoProtocolo(protocolo.protocoloNaFila, null, null, protocolo.dataCadastro));
                confirm(
                  "Atenção",
                  'Foi encontrado um registro para a pesquisa informada, mas ele ainda está aguardando na fila de processamento.<br><br>Deseja ser redirecionado para a tela de espera para acompanhar o processamento em tempo real?'
                ).then((result) => {
                  if (result.isConfirmed) {
                    window.location.replace('/#/aguarde')
                  }
                });
              } else {
                alertSuccess('Baixando arquivo...');
                const blob = base64ToArrayBuffer(protocolo.protocolo);
                saveByteArray(protocolo.nomeArquivo, blob);
              }
            });
          }
        } else {
          alertWarning(res.data.mensagem.replaceAll('"', ''));
        }
      })
      .catch(err => {
        closeLoading();
        alertWarning(err.response.data.replaceAll('"', ''));
      });
    })
  },
  obtenhaCandidatosNaFilaDeEspera(preMatriculaId: number,
    escolaId: number,
    serieId: number,
    filaComum: number,
    turno?: eTurno | null) {
      const url = `candidato/filadeespera/${preMatriculaId}/${escolaId}/${serieId}/${turno}/${filaComum ? 'true' : 'false'}`;
      return http.get<IResultApi>(url);
    }
}