
import ConsultaUnidadesPartial from "@/components/ConsultaUnidadesPartial.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    ConsultaUnidadesPartial
  },
})
export default class ConsultaUnidades extends Vue {
  
}

