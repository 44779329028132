export enum eCorRaca {
    NaoDeclarada,
    Branca,
    Preta,
    Parda,
    Amarela,
    Indigena
}

export const CorRacaLabel = new Map<number, string>([
    [eCorRaca.NaoDeclarada, 'Não declarada'],
    [eCorRaca.Branca, 'Branca'],
    [eCorRaca.Preta, 'Preta'],
    [eCorRaca.Parda, 'Parda'],
    [eCorRaca.Amarela, 'Amarela'],
    [eCorRaca.Indigena, 'Indígena'],
]);