import { DadosSocioeconomicosDTO } from "@/dtos/DadosSocioeconomicosDTO"
import { cacheHttp } from "@/request"

export default {
    obtenhaValoresPossiveis(itemsDadosSocioEconomicos: DadosSocioeconomicosDTO) {
        cacheHttp.get<DadosSocioeconomicosDTO>('/dadossocioeconomicos')
            .then(res => {
                itemsDadosSocioEconomicos.rendaFamiliar = res.data.rendaFamiliar;
                itemsDadosSocioEconomicos.tiposDeMoradia = res.data.tiposDeMoradia;
                itemsDadosSocioEconomicos.situacoesDeMoradia = res.data.situacoesDeMoradia;
                itemsDadosSocioEconomicos.destinacaoDoLixo = res.data.destinacaoDoLixo;
                itemsDadosSocioEconomicos.dependenciasFisicas = res.data.dependenciasFisicas;
                itemsDadosSocioEconomicos.tipoAbastecimentoDeAgua = res.data.tipoAbastecimentoDeAgua;
                itemsDadosSocioEconomicos.fontesDeEnergia = res.data.fontesDeEnergia;
                itemsDadosSocioEconomicos.dispositivosDeAcessoInternet = res.data.dispositivosDeAcessoInternet;
            });
    }
}