
import { Documento } from "@/models/Documento";
import { Component, Vue } from "vue-property-decorator";
import { confirm } from '@/utils/functions';

@Component
export default class ListaDeDocumentos extends Vue {

  documentos: Documento[] = this.$store.state.documentos;

  removerDocumentoAnexado(item: Documento) {
    confirm(
      "Remover anexo",
      `Deseja realmente remover o anexo ${item.descricao}?`
    ).then((result) => {
      if (result.isConfirmed) {
        this.$store.commit("removerArquivo", item);
      }
    });
  }
}
