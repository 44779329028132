import { CodigoDescricaoDTO } from "@/dtos/CodigoDescricaoDTO";
import localStorageService from "@/services/localStorageService";
import { alertError, alertWarning, closeLoading, onFilter, onFilterString, showLoading } from "@/utils/functions";
import { Component, Vue } from "vue-property-decorator";
import { obtenhaEscolasDaMatricula } from "@/services/escolaService";
import { eTurno, TurnoLabel, TodosOsTurnos } from "@/enums/eTurno";
import { CandidatoFila } from "@/models/candidatofila";
import { carregarSeries } from "@/services/serieAppService";
import candidatoService from "@/services/candidatoService";

@Component
export default class ConsultaFilaDeEsperaController extends Vue {
    configuracaoGeral = localStorageService.obtenhaConfiguracaoGeral();

    matriculas = new Array<CodigoDescricaoDTO>();
    matricula!: CodigoDescricaoDTO | null;

    escolas = new Array<CodigoDescricaoDTO>();
    escola!: CodigoDescricaoDTO | null;

    turno!: eTurno | null;
    turnos = TodosOsTurnos;

    serie!: CodigoDescricaoDTO | null;
    series = new Array<CodigoDescricaoDTO>();

    fila!: CodigoDescricaoDTO | null;
    filas = new Array<CodigoDescricaoDTO>();

    candidatos = new Array<CandidatoFila>();

    exibirCandidatos = false;

    constructor() {
        super();

        this.matricula = null;
        this.escola = null;
        this.turno = null;
        this.serie = null;
        this.fila = null;

        this.configuracaoGeral.tipos.filter(c => c.habilitaConsultaDeFila)
        .forEach(matricula => {
            const cd = new CodigoDescricaoDTO();
            cd.codigo = matricula.preMatriculaId;
            cd.descricao = matricula.tipoDescricao;
            this.matriculas.push(cd);
        });
    }

    filtre(item, queryText): boolean {
        return onFilter(item, queryText);
    }

    possuiMatriculaInformada() {
        return this.matricula && 
            this.matricula != null && 
            this.matricula != undefined && 
            this.matricula.codigo > 0;
    }

    possuiEscolaInformada() {
        return this.escola && 
            this.escola != null && 
            this.escola != undefined && 
            this.escola.codigo > 0;
    }

    possuiTurnoInformado() {
        return (this.turno || this.turno == 0);
    }

    changeTipoMatricula() {
        this.escola = null;
        this.escolas = new Array<CodigoDescricaoDTO>();
        this.filas = new Array<CodigoDescricaoDTO>();
        this.exibirCandidatos = false;
        this.candidatos = new Array<CandidatoFila>();
        this.turno = null;
        this.fila = null;
        this.serie = null;
        this.series = new Array<CodigoDescricaoDTO>();
        this.exibirCandidatos = false;
        this.candidatos = new Array<CandidatoFila>();

        if (this.possuiMatriculaInformada() && this.matricula) {

            let cd = new CodigoDescricaoDTO();
            cd.codigo = 1;
            cd.descricao = 'Fila Comum';
            this.filas.push(cd);

            const configuracao = this.configuracaoGeral.tipos.filter(c => c.preMatriculaId == this.matricula?.codigo);
            if (configuracao && configuracao[0].filaPrioritaria) {
                cd = new CodigoDescricaoDTO();
                cd.codigo = 0;
                cd.descricao = 'Fila Prioritária';
                this.filas.push(cd);
            }

            showLoading('Carregando unidades...');
            setTimeout(() => {
                obtenhaEscolasDaMatricula(this.matricula?.codigo ?? 0)
                .then(res => {
                    res.data.forEach(escola => {
                        this.escolas.push(escola);
                    });
                    closeLoading();
                })
                .catch(err => {
                    closeLoading();
                    alertError('Ocorreu um erro ao buscar as escolas.');
                });
            }, 1000);
        }
    }

    changeEscola() {
        this.turno = null;
        this.fila = null;
        this.serie = null;
        this.series = new Array<CodigoDescricaoDTO>();
        this.exibirCandidatos = false;
        this.candidatos = new Array<CandidatoFila>();
    }

    carregarSeries() {
        this.serie = null;
        this.series = new Array<CodigoDescricaoDTO>();
        this.exibirCandidatos = false;
        this.candidatos = new Array<CandidatoFila>();

        if (this.possuiMatriculaInformada() &&
            this.possuiEscolaInformada() &&
            this.possuiTurnoInformado()) 
        {
            showLoading('Carregando anos escolares...');
            setTimeout(() => {
                carregarSeries(this.matricula?.codigo ?? 0, this.escola?.codigo ?? 0, this.turno)
                .then(res => {
                    res.data.forEach(serie => {
                        this.series.push(serie);
                    });
                    closeLoading();
                })
                .catch(err => {
                    closeLoading();
                    alertError('Erro ao obter anos escolares.');
                });
            }, 1000);
        }
    }

    obtenhaDescricaoDoTurno(item: eTurno) {
        return TurnoLabel.get(item);
    }

    pesquisar() {
        this.candidatos = new Array<CandidatoFila>();
        this.exibirCandidatos = false;

        if (this.possuiMatriculaInformada() &&
            this.possuiEscolaInformada() &&
            this.possuiTurnoInformado()
            && this.fila
            && this.serie
            && this.serie.codigo > 0) 
        {
            showLoading('Carregando fila...');
            setTimeout(() => {
                candidatoService.obtenhaCandidatosNaFilaDeEspera(this.matricula?.codigo ?? 0,
                    this.escola?.codigo ?? 0,
                    this.serie?.codigo ?? 0,
                    this.fila?.codigo ?? 1,
                    this.turno)
                .then(res => {
                    const data = res.data;
                    if (data.sucesso) {
                        data.dados.forEach(candidato => {
                            this.candidatos.push(candidato);
                        });
                        closeLoading();
                        this.exibirCandidatos = true;
                    } else {
                        alertWarning(data.mensagem);
                    }
                })
                .catch(err => {
                    closeLoading();
                    alertError('Erro ao obter fila de candidatos.');
                });
            }, 1000);
        }
    }

    desabilitarUnidadeEFila() {
        return !this.matricula || this.matricula.codigo == 0;
    }

    desabilitarTurno() {
        return !this.escola || this.escola.codigo == 0;
    }

    desabilitarAnoEscolar() {
        return (this.turno == null);
    }
}