
import localStorageService from "@/services/localStorageService";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Footer extends Vue {
  obtenhaCorFooter() {
    return localStorageService.obtenhaConfiguracaoGeral().corSecundaria;
  }

  obtenhaUsuarioId() {
    return localStorage.getItem('usuarioid');
  }
}
