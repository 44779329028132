import { CadastroAntecipadoDTO } from "@/dtos/CadastroAntecipadoDTO";
import { CodigoDescricaoDTO } from "@/dtos/CodigoDescricaoDTO";
import { IResultApi } from "@/interfaces/IResultApi";
import { Documento } from "@/models/Documento";
import { http } from "@/request";

export default {
    obtenhaDadosDoCadastroAntecipado() {
        const url = `/cadastroantecipado`;
        return http.get<CadastroAntecipadoDTO>(url);
    },
    addCandidato(parametros: {}, documentos: Documento[]) {
        const formData = new FormData();

        formData.append('candidato', JSON.stringify(parametros));
        if (documentos && documentos.length > 0) {
          for (let i = 0; i< documentos.length; i++) {
            formData.append(`files`, documentos[i].arquivo);
          }
        }
    
        return http.post<IResultApi>(`/cadastroantecipado`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
      },
      existeCandidatoComCpf(cpf: string) {
        const url = `/cadastroantecipado/candidatocomcpf/${cpf.replaceAll('.', '').replaceAll('-', '')}`;
        return http.get<IResultApi>(url);
      },
      tiposDeDocumento() {
        const url = `/cadastroantecipado/tiposdedocumento`;
        return http.get<CodigoDescricaoDTO[]>(url);
      }
}