import { render, staticRenderFns } from "./Protocolos.vue?vue&type=template&id=4cee6bbe&scoped=true&"
import script from "@/controllers/ProtocolosController.ts?vue&type=script&lang=ts&"
export * from "@/controllers/ProtocolosController.ts?vue&type=script&lang=ts&"
import style0 from "./Protocolos.vue?vue&type=style&index=0&id=4cee6bbe&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cee6bbe",
  null
  
)

export default component.exports