import { eParentesco } from '@/enums/eParentesco';
import { eSexo } from '@/enums/eSexo';
import { Guid } from "guid-typescript";
import { Deficiencia } from './Deficiencia';

export class Responsavel {
    public id = Guid.create().toString();
    public nome = '';
    public celular = '';
    public celularFixo!: string;
    public email = '';
    public cpf = '';
    public rg!: string;
    public dataNascimento!: Date | null;
    public localTrabalho!: string;
    public telefoneTrabalho!: string;
    public renda = '';
    public trabalha!: boolean;
    public sexo!: eSexo;
    public parentesco!: eParentesco | null;
    public possuiDeficienciaComLaudo!: boolean;
    public deficienciaId!: number | null;
}