import { eParentesco } from '@/enums/eParentesco';

export class Solicitante {
    public nome!: string;
    public telefone!: string;
    public telefoneSecundario: string | null = null;
    public parentesco: eParentesco | null = null;    
    public email: string | null = null;
    public cpf: string | null = null;
    public ehResponsavelDoCandidato: boolean | null = null;
    public ehOProprioCandidato!: boolean;
    public outroParentesco: string | null = null;
}