import { IResultApi } from "@/interfaces/IResultApi";
import { http } from "@/request";

export default {
    obtenhaAluno(cpf: string | null, sus: string | null) {
        cpf = cpf ? cpf.replace(/\D/g, "") : '';
        sus = sus ? sus.replace(/\D/g, "") : '';

        const url = `/solicitacao/aluno?cpf=${cpf}&sus=${sus}`;
        return http.get<IResultApi>(url);
    },
    salvarSolicitacao(parametros: {}) {
        return http.post<IResultApi>(`/solicitacao/nova`, parametros);
    },
    obtenhaProtocolo(id: number) {
        const url = `/solicitacao/protocolo/${id}`
        return http.get<IResultApi>(url);
    }
}