
import { eNivelModalidadeEnsino } from "@/enums/eNivelModalidadeEnsino";
import { eTipoFinalizacaoAtendimento } from "@/enums/eTipoFinalizacaoAtendimento";
import app from "@/main";
import { Atendimento } from "@/models/Atendimento";
import callCenterService from "@/services/callCenterService";
import { Component, Prop, Vue } from "vue-property-decorator";
import CadastroAluno from "../components/CadastroAluno.vue";

@Component({
  components: {
    CadastroAluno
  },
})
export default class Cadastro extends Vue {
  @Prop() ehInfantil!: boolean;
  @Prop() tipoPreMatricula!: eNivelModalidadeEnsino;

  mounted() {
    window.addEventListener('beforeunload', this.finalizeAtendimentoNoUnload);
  }

  finalizeAtendimentoNoUnload(){
    const atendimento: Atendimento = app.$store.state.atendimento;
    if(atendimento.id){
      callCenterService.finalizeAtendimento(atendimento.id, eTipoFinalizacaoAtendimento.AutomaticamentePeloNavegador);
    }
  }
}
