import { ConfiguracaoSeriePreMatriculaDTO } from "@/dtos/ConfiguracaoSeriePreMatriculaDTO";
import { EscolaPreMatriculaDTO } from "@/dtos/EscolaPreMatriculaDTO";
import { Aluno } from "@/models/Aluno";
import { Deficiencia } from "@/models/Deficiencia";
import { enumHasValue, menorQue5Anos, validaCelular, validaCNS, validaCNSProvisorio, validaCpf, validaData, validaNis, validaUnidadeConsumidora } from "./functions";
import app from '@/main';
import { eParametroPreMatricula } from "@/enums/eParametroPreMatricula";
import { IParametros } from "@/interfaces/IParametros";
import { CodigoDescricaoTurnoDTO } from "@/dtos/CodigoDescricaoTurnoDTO";
import { EscolaPrefencialDTO } from "@/dtos/EscolaPreferencialDTO";
import { CandidatoCadastroAntecipado } from "@/models/CandidatoCadastroAntecipado";
import { eParentesco } from "@/enums/eParentesco";
import { eCorRaca } from "@/enums/eCorRaca";

function getRulesCpf(cpf: string): boolean {
  const parametros: IParametros = app.$store.state.parametros;
  if (parametros.cpfCandidato == eParametroPreMatricula.Sim) return true;
  if (parametros.cpfCandidato == eParametroPreMatricula.SimObrigatorio && !cpf) return false;
  return true;
}

function getRulesSUS(sus: string): boolean {
  const parametros: IParametros = app.$store.state.parametros;
  if (parametros.cartaoSusCandidato == eParametroPreMatricula.Sim) return true;
  if (parametros.cartaoSusCandidato == eParametroPreMatricula.SimObrigatorio && !sus) return false;
  return true;
}

function getRulesDeficiencia(def: Deficiencia, deficiente: boolean): boolean {
  if (deficiente && def.id == undefined) {
    return false;
  } else {
    return true;
  }
}

function validaDataFundamental(dataNascimento: string): boolean {
  return menorQue5Anos(dataNascimento);
}

function getRulesEscola(esc: EscolaPreMatriculaDTO, listaDeEscola: CodigoDescricaoTurnoDTO[]): boolean {
  return listaDeEscola.length === 0 || esc.id !== undefined;
}

function getRulesSerie(serie: ConfiguracaoSeriePreMatriculaDTO): boolean {
  return serie.id != undefined;
}

function getRulesTempoResidencia(tempo: string) {
  const parametros: IParametros = app.$store.state.parametros;
  if (parametros.informarTempoMoradia == eParametroPreMatricula.Sim) return true;
  if (parametros.informarTempoMoradia == eParametroPreMatricula.SimObrigatorio && (!tempo || tempo.length > 15)) return false;
  return true;
}

function getRulesNis(nis: string): boolean {
  const parametros: IParametros = app.$store.state.parametros;
  if (parametros.informarNumeroNis == eParametroPreMatricula.Sim) return true;
  if (parametros.informarNumeroNis == eParametroPreMatricula.SimObrigatorio && !nis) return false;
  return true;
}

function getRulesFile(file: File) {
  const parametros: IParametros = app.$store.state.parametros;
  if (parametros.anexarComprovanteResidencia == eParametroPreMatricula.Sim) return true;
  if (parametros.anexarComprovanteResidencia == eParametroPreMatricula.SimObrigatorio && file.name == "") return false;
  return true;
}

function getRulesCelularPrincipal(celularPrincipal: string) {
  const parametros: IParametros = app.$store.state.parametros;
  if (parametros.informarCelularPrincipal == eParametroPreMatricula.Sim) return true;
  if (parametros.informarCelularPrincipal == eParametroPreMatricula.SimObrigatorio && !celularPrincipal) return false;
  return true;
}

function obtenhaRegrasCorRaca(corRaca: eCorRaca) {
  if (corRaca >= 0) return true;
  const parametros: IParametros = app.$store.state.parametros;
  if (parametros.corRaca == eParametroPreMatricula.Sim) return true;
  if (parametros.corRaca == eParametroPreMatricula.SimObrigatorio && (!corRaca)) return false;
  return true;
}

function getRulesCelularSecundario(celularSecundario: string) {
  const parametros: IParametros = app.$store.state.parametros;
  if (parametros.informarCelularSecundario == eParametroPreMatricula.Sim) return true;
  if (parametros.informarCelularSecundario == eParametroPreMatricula.SimObrigatorio && !celularSecundario) return false;
  return true;
}

function getRulesUnidadeConsumidora(unidadeConsumidora: string) {
  const parametros: IParametros = app.$store.state.parametros;
  if (parametros.informarUnidadeConsumidora == eParametroPreMatricula.Sim) return true;
  if (parametros.informarUnidadeConsumidora == eParametroPreMatricula.SimObrigatorio && !unidadeConsumidora) return false;
  return true;
}

function getRulesEscolaPreferencial(i: number, escola: EscolaPrefencialDTO) {
  const parametros: IParametros = app.$store.state.parametros;
  if (parametros.quantidadeOpcoesEscolaObrigatorio > 0
    && (i + 1) <= parametros.quantidadeOpcoesEscolaObrigatorio
    && (!escola || escola.codigo == null)) {
      return false;
  }
  return true;
}

function getRulesTurno(escola: EscolaPrefencialDTO, turno: string) {
  if (escola
    && escola.codigo
    && !turno) {
      return false;
  }

  return true;
}

function getRulesIrmaoNaRede(cpf: string, sus: string, possuiIrmao: boolean) {
  const parametros: IParametros = app.$store.state.parametros;
  if (parametros.informarIrmaoNaRede == eParametroPreMatricula.Sim) return true;
  if (parametros.informarIrmaoNaRede == eParametroPreMatricula.SimObrigatorio && (possuiIrmao && (!cpf || !sus))) return false;
  return true;
}

function turnoDesabilitado() {
  const parametros: IParametros = app.$store.state.parametros;
  return !parametros.habilitaSelecaoDeTurno;
}

function validaCep(cep: string): boolean {
  if (!cep) return false;
  cep = cep.replace('.', '').replace('-', '');
  if (cep.length < 8) return false;
  return true;
}

function possuiValor(valor: string, tamanho: number) {
  if (!valor) {
    return true;
  }

  if (valor && valor.length <= tamanho) {
    return true;
  }

  return false;
}

export default {
  getRulesCandidato(aluno: Aluno,
    deficiente: boolean,
    dataNascimento: string,
    escolas: CodigoDescricaoTurnoDTO[],
    escolasSelecionadas: EscolaPrefencialDTO[]) {
    const validate = {
      nome: [
        (v: string) => !!v || aluno.maeGestante || "Nome é obrigatório",
        (v: string) =>
          v.length <= 300 || "Nome deve ter no máximo 300 caracteres",
      ],
      cpf: [
        (v: string) => getRulesCpf(v) || aluno.maeGestante || "CPF é obrigatório",
        (v: string) => validaCpf(v) || "CPF Inválido",
      ],
      sus: [
        (v: string) => getRulesSUS(v) || aluno.maeGestante || "Número do Cartão SUS é obrigatório",
        (v: string) =>
          validaCNS(v) ||
          validaCNSProvisorio(v) ||
          aluno.maeGestante ||
          "Número do Cartão SUS inválido",
      ],
      sexo: [(v: number) => !!v || v == 0 || aluno.maeGestante || "Sexo é obrigatório"],
      dataNascimento: [
        (v: string) => !!v || "Data de nascimento é obrigatório",
        (v: string) => validaData(v) || "Data de nascimento inválida",
      ],
      cep: [
        (v: string) => !!v || "CEP é obrigatório",
        (v: string) => validaCep(v) || "CEP inválido" 
      ],
      zonaResidencia: [
        (v: number) => !!v || v == 0 || "Zona da residência é obrigatório",
      ],
      turno: [(v: number) => turnoDesabilitado() || !!v || v == 0 || "Turno é obrigatorio"],
      deficiencia: [
        (v: Deficiencia) =>
          getRulesDeficiencia(v, deficiente) ||
          "Escolha a deficiência do estudante",
      ],
      municipio: [(v: number) => !!v || v == 0 || "O município onde deseja estudar é obrigatorio para filtrar as escolas. Selecione o estado na parte de endereço para que as cidades sejam exibidas."],
      complemento: [
        (v: string) =>
        possuiValor(v, 150) || "Complemento deve ter no máximo 150 caracteres",
      ],
      numero: [
        (v: string)=>
        possuiValor(v, 10) || "Número do endereço deve conter no máximo 10 caracteres",
      ],
      observacao: [
        (v: string) =>
        possuiValor(v, 300) || "Observação deve ter no máximo 300 caracteres",
      ],
      haDocApto: [
        (v: boolean) =>
          validaDataFundamental(dataNascimento) && !v ||
          v ||
          "Para alunos com menos de 5 anos, é obrigatório confirmar a existência de documentação comprobatória de que o aluno está apto a cursar o ensino fundamental",
      ],
      unidadeEntrega: [
        (v: EscolaPreMatriculaDTO) => getRulesEscola(v, escolas) || 'Escolha a unidade para entrega de documentos'
      ],
      serie: [
        (v: ConfiguracaoSeriePreMatriculaDTO) => getRulesSerie(v) || 'Selecione uma série'
      ],
      tempoResidencia: [
        (v: string) => getRulesTempoResidencia(v) || 'Tempo de morádia inválido, digite no máximo 15 caracteres.'
      ],
      numeroNIS: [
        (v: string) => getRulesNis(v) || aluno.maeGestante || "Número NIS é obrigatório",
        (v: string) => validaNis(v) || "Número NIS inválido"
      ],
      celularPrincipal: [
        (v: string) => getRulesCelularPrincipal(v) || aluno.maeGestante || "O telefone principal é obrigatório",
        (v: string) => validaCelular(v) || "O telefone informado é inválido"
      ],
      celularSecundario: [
        (v: string) => getRulesCelularSecundario(v) || aluno.maeGestante || "O telefone secundário é obrigatório",
        (v: string) => validaCelular(v) || "O telefone informado é inválido"
      ],
      unidadeConsumidora: [
        (v: string) => getRulesUnidadeConsumidora(v) || "A Unidade Consumidora é obrigatória",
        (v: string) => validaUnidadeConsumidora(v) || "A Unidade Consumidora é inválida"
      ],
      file: [
        (v: File) => getRulesFile(v) || "É obrigatório anexar o comprovante de endereço.",
        (v: File) => v.size < 1000000 || "Não é permitido enviar um arquivo maior que 1MB"
      ],
      bairro: [
        (v: string) => !!v || "Bairro é obrigatório"
      ],
      estado: [
        (v: string) => !!v || "Estado é obrigatório"
      ],
      cidade: [
        (v: string) => !!v || "Cidade é obrigatório"
      ],
      logradouro: [
        (v: string) => !!v || "Logradouro é obrigatório"
      ],
      escolaOrigem: [
        (v: string) => !v || v.length <= 300 || "Escola de origem deve conter no máximo 300 caracteres"
      ],
      deficienciaDescricao: [
        (v: string) =>  !!v || aluno.exibirOutraDeficiencia || "Quando outra deficiência for informada, deve-se descrever a deficiência.",
        (v: string) =>  v.length <= 50 || "A descrição da deficiência deve conter até no máximo 50 caracteres.",
      ],
      cpfIrmao: [
        (v: string) => getRulesIrmaoNaRede(v, aluno.cartaoSusIrmao, aluno.possuiIrmao) || (!!v || !!aluno.cartaoSusIrmao)  || "CPF é obrigatório",
        (v: string) => validaCpf(v) || "CPF Inválido",
      ],
      cartaoSusIrmao: [
        (v: string) => getRulesIrmaoNaRede(aluno.cpfIrmao, v, aluno.possuiIrmao) || (!!v || !!aluno.cpfIrmao) || "Número do Cartão SUS é obrigatório",
        (v: string) =>
          validaCNS(v) ||
          validaCNSProvisorio(v) ||
          "Número do Cartão SUS inválido",
      ], 
      valideEscolaPreferencial(i: number) {
        return [
          (v: EscolaPrefencialDTO) => getRulesEscolaPreferencial(i, v) || "Escola obrigatória.",
        ]
      },
      valideTurnoPreferencial(escola: EscolaPrefencialDTO) {
        return [
          (v: string) => getRulesTurno(escola, v) || "Turno obrigatório."
        ]
      },
      corRaca: [
        (v: eCorRaca) => obtenhaRegrasCorRaca(v) || "A cor/raça é obrigatória",
      ],
    };

    return validate;
  },
  obtenhaRegrasDeValidacaoDoCadastroAntecipado(candidato: CandidatoCadastroAntecipado) {
    const validate = {
      nome: [
        (v: string) => !!v || 'O nome é obrigatório.',
        (v: string) => (v && v.length <= 300) || 'O nome deve conter até 300 caracteres.'
      ],
      cpf: [
        (v: string) => !!v || 'O cpf é obrigatório.',
        (v: string) => validaCpf(v) || "O cpf informado é inválido.",
      ],
      sexo: [
        (v: number) => !!v || v == 0 || 'O sexo é obrigatório.'
      ],
      dataNascimento: [
        (v: string) => !!v || "A data de nascimento é obrigatória.",
        (v: string) => validaData(v) || "A data de nascimento informada é inválida.",
      ],
      cartaoSus: [
        (v: string) => validaCNS(v) || validaCNSProvisorio(v) || "O cartão SUS informado é inválido.",
      ],
      nis: [
        (v: string) => validaNis(v) || "Número NIS inválido"
      ],
      cep: [
        (v: string) => !!v || "O cep é obrigatório.",
        (v: string) => validaCep(v) || "CEP inválido" 
      ],
      estado: [
        (v: string) => !!v || 'O estado é obrigatório.'
      ],
      cidade: [
        (v: string) => !!v || 'A cidade é obrigatória.'
      ],
      bairro: [
        (v: number) => !!v || 'O bairro é obrigatório.'
      ],
      telefonePrincipal: [
        (v: string) => validaCelular(v) || 'O telefone informado é inválido.'
      ],
      telefoneSecundario: [
        (v: string) => validaCelular(v) || 'O telefone informado é inválido.'
      ],
      responsavelNome: [
        (v: string) => !!v || 'O nome é obrigatório.',
        (v: string) => (v && v.length <= 300) || 'O nome deve conter até 300 caracteres.'
      ],
      responsavelTelefonePrincipal: [
        (v: string) => !!v || 'O telefone principal deve ser informado.',
        (v: string) => validaCelular(v) || "O telefone informado é inválido."
      ],
      responsavelTelefoneSecundario: [
        (v: string) => validaCelular(v) || 'O telefone informado é inválido.'
      ],
      responsavelCpf: [
        (v: string) => validaCpf(v) || 'O cpf informado é inválido.',
        (v: string) => ((!candidato.cpf || !v) || candidato.cpf != v) || 'O cpf do responsável deve ser diferente do candidato.'
      ],
      responsavelParentesco: [
        (v: number) => !!v || v == 0 || 'O parentesco é obrigatório.'
      ],
      rendaFamiliar: [
        (v: number) => !!v || 'A renda familiar é obrigatória.'
      ],
      responsavelTipoDeTrabalhoMae: [
        (v: number) => !!v ||(candidato.responsavelParentesco != eParentesco.Mae) || (v == 0) || 'O tipo de trabalho da mãe é obrigatório.'
      ]
    };

    return validate;
  },
  obtenhaRegrasParaSolicitacaoDeTransferencia(permiteSelecaoDeTurno: boolean, solicitacaoInterna: boolean | null) {
    return {
      cpfSolicitante: [
        (v: string) => !!v || 'O cpf do solicitante é obrigatório.',
        (v: string) => validaCpf(v) || 'Insira um cpf válido.',
      ],
      nomeSolicitante: [
        (v: string) => !!v || 'O nome do solicitante é obrigatório.'
      ],
      escolaSelecionadada: [
        (v: number) => !!v || !solicitacaoInterna || 'A escola para transferência é obrigatória.'
      ],
      turnoDeDestino: [
        (v: number) => enumHasValue(v) || !permiteSelecaoDeTurno || !solicitacaoInterna || 'O turno é obrigatório.'
      ],
      solicitacaoSelecionada: [
        (v: string) => !!v || 'O tipo da solicitação é obrigatório.'
      ]
    };
  }
}