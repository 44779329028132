import { AlunoSolicitacaoDeTransferenciaDTO } from "@/dtos/AlunoSolicitacaoDeTransferenciaDTO";
import { CodigoDescricaoDTO } from "@/dtos/CodigoDescricaoDTO";
import { eTurno, TurnoLabel, TodosOsTurnos } from "@/enums/eTurno";
import { IResultApi } from "@/interfaces/IResultApi";
import { http } from "@/request";
import { obtenhaEscolasParaSolicitacaoDeTransferencia } from "@/services/escolaService";
import localStorageService from "@/services/localStorageService";
import solicitacaoService from "@/services/solicitacaoService";
import { alertSuccess, alertWarning, base64ToArrayBuffer, closeLoading, enumHasValue, onFilter, possuiValor, saveByteArray, showLoading, validaCNS, validaCpf } from "@/utils/functions";
import rules from "@/utils/rules";
import { Component, Vue } from "vue-property-decorator";
import store from '@/store/index';

@Component({
    components: {},
})

export default class SolicitacaoDeTransferencia extends Vue {
    cpfSolicitante = '';
    nomeSolicitante = '';
    aluno = new AlunoSolicitacaoDeTransferenciaDTO();
    cpf = '';
    sus = '';
    documentoInformado = false;
    desabilitarCpf = false;
    desabilitarSus = false;
    escolas = new Array<CodigoDescricaoDTO>();
    turnos = TodosOsTurnos;
    tipoInterna = "INTERNA - OUTRA UNIDADE DESSA MESMA REDE DE ENSINO";
    tipoExterna = "EXTERNA - SAIR DA REDE DE ENSINO ATUAL";
    tiposDeSolicitacao = [this.tipoInterna, this.tipoExterna];
    solicitacaoSelecionada = '';
    solicitacaoInterna: boolean | null = null;
    parametros = localStorageService.obtenhaConfiguracaoGeral().parametrosSolicitacaoDeTransferencia;
    concordo = false;
    regras = rules.obtenhaRegrasParaSolicitacaoDeTransferencia(this.parametros.selecaoDeTurno, null);
    exibirCampoParaSelecionarTipoDeSolicitacao = true;
    excedente = false;
    usuarioId = '';

    async beforeMount() {
        const response = await http.get<IResultApi>(`/solicitacao/parametros`);
        if (response.status == 200) {
            this.parametros = (response.data.dados);
        } else {
            alertWarning('Ocorreu um erro inesperado ao obter os dados para a solicitação de transferência. Aguarde alguns minutos e tente novamente.');
            this.$router.push('/');
        }
    }

    constructor() {
        super();

        if (!this.parametros?.processoParaForaDaRede) {
            this.solicitacaoInterna = true;
            this.solicitacaoSelecionada = this.tipoInterna;
            this.exibirCampoParaSelecionarTipoDeSolicitacao = false;
        }

        const solicitante = store.state.solicitante;
        if (solicitante) {
            this.cpfSolicitante = solicitante.cpf || '';
            this.nomeSolicitante = solicitante.nome || '';
        }

        const usuarioId = localStorageService.getItem('usuarioid');
        if (possuiValor(usuarioId) && usuarioId != 'null') {
            this.usuarioId = usuarioId ?? '';
        }
    }

    changeCpf() {
        if (this.cpf != '' && this.cpf != null && this.cpf != undefined) {
            if (!validaCpf(this.cpf)) {
                alertWarning('Informe um cpf válido para prosseguir.');
                this.cpf = '';
                return;
            }

            showLoading('Buscando aluno...');

            setTimeout(() => {
                solicitacaoService.obtenhaAluno(this.cpf, this.sus)
                .then(res => {
                    closeLoading();
                    if (res.data.sucesso) {
                        this.sus = '';
                        this.desabilitarSus = true;
                        this.documentoInformado = true;

                        this.preenchaDados(res.data.dados);
                        this.carregueEscolas(false);
                    } else {
                        alertWarning(res.data.mensagem);
                        this.cpf = '';
                        this.desabilitarCpf = false;

                        this.sus = '';
                        this.desabilitarSus = false;

                        this.documentoInformado = false;
                    }
                })
                .catch(err => {
                    closeLoading();
                    alertWarning(err.message);
                });
            }, 1000);
        } else {
            this.documentoInformado = false;
            this.desabilitarSus = false;
        }
    }

    changeSus() {
        if (this.sus != '' && this.sus != null && this.sus != undefined) {
            if (!validaCNS(this.sus)) {
                alertWarning('Informe um sus válido para prosseguir.');
                this.sus = '';
                return;
            }

            showLoading('Buscando aluno...');

            setTimeout(() => {
                solicitacaoService.obtenhaAluno(this.cpf, this.sus)
                .then(res => {
                    closeLoading();
                    if (res.data.sucesso) {
                        this.cpf = '';
                        this.desabilitarCpf = true;
                        this.documentoInformado = true;
    
                        this.preenchaDados(res.data.dados);
                        this.carregueEscolas(false);
                    } else {
                        alertWarning(res.data.mensagem);
                        this.cpf = '';
                        this.desabilitarCpf = false;
    
                        this.sus = '';
                        this.desabilitarSus = false;
    
                        this.documentoInformado = false;
                    }
                })
                .catch(err => {
                    closeLoading();
                    alertWarning(err.message);
                });
            }, 1000);
        } else {
            this.desabilitarCpf = false;
            this.documentoInformado = false;
        }
    }

    filtre(item, queryText): boolean {
        return onFilter(item, queryText);
    }

    preenchaDados(dados: any) {
        this.aluno.alunoId = dados.alunoId;
        this.aluno.nome = dados.nome;
        this.aluno.dataDeNascimento = dados.dataDeNascimento;
        this.aluno.escolaDeOrigemId = dados.escolaDeOrigemId;
        this.aluno.escolaDeOrigem = dados.escolaDeOrigem;
        this.aluno.turnoDeOrigem = dados.turnoDeOrigem;
        this.aluno.serieDeDestino = dados.serieDeDestino;
    }

    obtenhaTamanhoDaColunaDeEscola() {
        return this.parametros.selecaoDeTurno ? 8 : 12;
    }

    getTurnoDescription(item: eTurno) {
        return TurnoLabel.get(item);
    }

    carregueEscolas(abrirLoading: boolean) {
        this.escolas = new Array<CodigoDescricaoDTO>();
        this.aluno.escolaDeDestino = null;
        if (this.parametros.selecaoDeTurno && !enumHasValue(this.aluno.turnoDeDestino)) {
            if (!abrirLoading) {
                closeLoading();
            }
            return;
        }

        if (abrirLoading) {
            showLoading('Carregando escolas...');
        }
        setTimeout(() => {
            obtenhaEscolasParaSolicitacaoDeTransferencia(this.aluno.serieDeDestino.codigo, this.aluno.escolaDeOrigemId, this.aluno.turnoDeDestino, this.excedente)
            .then(res => {
                closeLoading();
                if (res.data.sucesso) {
                    res.data.dados.forEach(item => {
                        this.escolas.push(item);
                    });
                } else {
                    alertWarning(res.data.mensagem);
                }
            })
            .catch(err => {
                closeLoading();
                alertWarning(err.message);
            });
        }, 750);
    }

    salvar() {
        const validacao = (this.$refs.form as Vue & {
            validate: () => boolean;
        }).validate();

        if (validacao) {
            if (this.cpfSolicitante == this.cpf) {
                alertWarning('O cpf do solicitante e do aluno devem ser diferentes.');
                return;
            }

            showLoading('Salvando...');
            const dto = {
                AlunoId:            this.aluno.alunoId,
                EscolaDeDestinoId:  this.aluno.escolaDeDestino?.codigo,
                TurnoDeDestino:     this.aluno.turnoDeDestino,
                CpfSolicitante:     this.cpfSolicitante,
                NomeSolicitante:    this.nomeSolicitante,
                Excedente:          this.excedente,
                UsuarioId:          this.usuarioId == '' ? null : parseInt(this.usuarioId)
            };

            solicitacaoService.salvarSolicitacao(dto)
                .then(res => {
                    if (res.data.sucesso) {
                        closeLoading();
                        alertSuccess(res.data.mensagem);
                        this.$router.push('/');

                        solicitacaoService.obtenhaProtocolo(res.data.dados.id)
                            .then((res) => {
                                if (res.data.sucesso) {
                                const blob = base64ToArrayBuffer(res.data.dados.protocolo);
                                saveByteArray('Protocolo - Solicitação de Transferência', blob);

                                if (res.data.dados.declaracaoDeTransferencia) {
                                    const blob = base64ToArrayBuffer(res.data.dados.declaracaoDeTransferencia);
                                    saveByteArray('Declaração de Transferência', blob);
                                }
                                } else {
                                alertWarning(res.data.mensagem.replaceAll('"', ''));
                                }
                            }).catch(err => {
                                alertWarning(err.response.data.replaceAll('"', ''));
                            });
                    } else {
                        alertWarning(res.data.mensagem);
                    }
                })
                .catch(err => {
                    closeLoading();
                    alertWarning('Ocorreu um erro inesperado ao tentar salvar a solicitação. Contate o administrador do sistema.')
                });
            
        } else {
            alertWarning('Existem dados obrigatórios não preenchidos. Revise o formulário.')
        }
    }

    changeTipoDeTransferencia(e: string) {
        this.aluno.escolaDeDestino = null;
        this.aluno.turnoDeDestino = null;
        this.escolas = new Array<CodigoDescricaoDTO>();

        switch (e) {
            case this.tipoInterna:
                this.solicitacaoInterna = true;
                break;
            case this.tipoExterna:
                this.solicitacaoInterna = false;
                break;
            default:
                this.solicitacaoInterna = null;
                break;
        }

        this.regras = 
            rules.obtenhaRegrasParaSolicitacaoDeTransferencia(this.parametros.selecaoDeTurno, this.solicitacaoInterna);
    }
}