
import { Component, Vue } from "vue-property-decorator";
import Passos from "@/components/inicio/Passos.vue";
import { eNivelModalidadeEnsino } from "@/enums/eNivelModalidadeEnsino";
import ModalConsultaProtocolo from "../components/ModalConsultaProtocolo.vue";
import ModalSolicitante from "@/components/ModalSolicitante.vue";
import app from "@/main";
import callCenterService from "@/services/callCenterService";
import localStorageService from "@/services/localStorageService";
import { eTipoFinalizacaoAtendimento } from "@/enums/eTipoFinalizacaoAtendimento";
import { Propriedades } from "@/models/Propriedades";
import { ConfiguracaoGeral } from "@/models/ConfiguracaoGeral";
import { getUrlCache } from "@/request";
import { obtenhaCorDeTextoComBaseNaCorDeFundo, possuiValor } from "@/utils/functions";

@Component({
  components: {
    Passos,
    ModalConsultaProtocolo,
    ModalSolicitante
  },
})
export default class Inicio extends Vue {
  configuracaoGeral: ConfiguracaoGeral = localStorageService.obtenhaConfiguracaoGeral();

  titulo: string | null = this.configuracaoGeral.titulo;
  tipos = this.configuracaoGeral.tipos;
  informacoes: string | null = this.configuracaoGeral.textoAuxiliar;

  mounted() {
    if(app.$store.state.ehPeloCallCenter){
      (this.$refs.modalSolicitante as ModalSolicitante & {
        openModal: () => void;
      }).openModal();
    }
    window.addEventListener('beforeunload', this.finalizeAtendimentoNoUnload);

    if (this.titulo) {
      window.document.title = this.titulo;
    }
  }

  callcenter() {
    const usuarioId = localStorageService.getItem('usuarioid') || '';
    return possuiValor(usuarioId) && usuarioId != 'null';
  }

  async finalizeAtendimentoNoUnload() {
    this.finalizeAtendimento(eTipoFinalizacaoAtendimento.AutomaticamentePeloNavegador);
  }

  async finalizeAtendimento(tipoFinalizacao: eTipoFinalizacaoAtendimento){
    const atendimento = this.$store.state.atendimento;
    const usuarioId = localStorageService.getItem('usuarioid') || '';
    if(atendimento.id){
      callCenterService.finalizeAtendimento(atendimento.id, tipoFinalizacao);
      this.$store.commit("clearAtendimento");
    }
    if(tipoFinalizacao != eTipoFinalizacaoAtendimento.AutomaticamentePeloNavegador){
      this.$store.commit('setFlagCallCenter', true);
      localStorageService.setUsuarioId(usuarioId);
      this.showModalSolicitanteVazio();
    }
  }

  showModalSolicitanteVazio(){
    (this.$refs.modalSolicitante as ModalSolicitante & {
        openModal: () => void;
      }).openModalVazio();
  }

  obtenhaUrlImagemDeFundo() {
    return `${getUrlCache()}/image/imagemdefundo`;
  }

  openModalConsultaProtocolo() {
    (this.$refs.modalConsultaProtocolo as ModalConsultaProtocolo & {
      openModal: () => void;
    }).openModal();
  }

  obtenhaCorPrimaria(){
    return this.configuracaoGeral.corPrimaria;
  }

  redirecione(id: number, item: eNivelModalidadeEnsino, ehMilitar: boolean) {
    localStorageService.atualizePropriedades(new Propriedades(id, ehMilitar, item == eNivelModalidadeEnsino.EducacaoInfantil, null));
    this.$router.push({ name: 'Cadastro' });
  }

  obtenhaCorSecundaria() {
    return this.configuracaoGeral.corSecundaria;
  }

  obtenhaVideoDeAjuda() {
    return this.configuracaoGeral.videoDeAjuda;
  }

  cadastroAntecipadoHabilitado() {
    const parametro = this.configuracaoGeral.cadastroAntecipadoHabilitado;
    return parametro != null && parametro;
  }

  abrirVideoDeAjuda() {
    window.open(this.obtenhaVideoDeAjuda() ?? '');
  }

  obtenhaCorDoTexto(c: string) {
    return obtenhaCorDeTextoComBaseNaCorDeFundo(c);
  }
}
