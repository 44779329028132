export enum eTipoDeTrabalhoMae {
  Autonoma = 0,
  Contracheque = 1,
  BPC = 2,
  NaoTrabalha = 3
}

  export const TipoDeTrabalhoMaeLabel = new Map<number, string>([
    [eTipoDeTrabalhoMae.Autonoma, '- A Mãe é trabalhadora autônoma e pode comprovar com comprovante de pagamento de contribuição ao INSS ou Declaração de Regularidade da Situação do Contribuinte Individual atualizada.'],
    [eTipoDeTrabalhoMae.Contracheque, '- A Mãe é trabalhadora e pode comprovar com Carteira de Trabalho ou Contracheque.'],
    [eTipoDeTrabalhoMae.BPC, '- Mãe é Trabalhadora Benefício Prestação Continuada e pode Comprovar com Extrato do INSS e Cartão de Recebimento do BPC.'],
    [eTipoDeTrabalhoMae.NaoTrabalha, '- Não é trabalhadora ou não pode comprovar.']
]);