
import { Component, Vue } from "vue-property-decorator";
import Swal from 'sweetalert2';
import localStorageService from "@/services/localStorageService";
import { getUrlCache } from "@/request";

@Component({})
export default class AppBar extends Vue {
  drawer = false;
  configuracaoGeral = localStorageService.obtenhaConfiguracaoGeral();
  tipos = this.configuracaoGeral.tipos;

  obtenhaCorHeader() {
    return this.configuracaoGeral.corPrimaria;
  }

  obtenhaUrlLogo() {
    return `${getUrlCache()}/image/logo`;
  }

  obtenhaUsuarioId() {
    return localStorage.getItem('usuarioid');
  }

  navegaInicio() {
    if (this.$router.currentRoute.name !== "Inicio") {
      Swal.fire({
        title: 'Atenção!',
        html: '<br><b>Todas as alterações nessa tela serão perdidas.</b><br>Deseja mesmo ir para a tela inicial?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push("/");
        }
      });
    }
  }

  async navega(rota: string) {
    this.drawer = false;
    if (this.$router.currentRoute.name !== rota){
      this.$router.push({ path: rota });
    }
  }
}
