import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Error from '../views/Error.vue'
import Protocolos from '../views/Protocolos.vue'
import Inicio from '../views/Inicio.vue';
import Cadastro from '../views/Cadastro.vue';
import ConsultaUnidades from '../views/ConsultaUnidades.vue';
import ConsultaFilaDeEspera from '../views/ConsultaFilaDeEspera.vue';
import CadastroAntecipado from '../views/CadastroAntecipado.vue';
import Transferencia from '../views/Transferencia.vue';
import Countdown from '../components/home/Countdown.vue';
import cadastroAntecipadoService from '@/services/cadastroAntecipadoService';
import localStorageService from '@/services/localStorageService';
import { alertInfo, alertWarning, possuiValor } from '@/utils/functions';
import app from '@/main';
import { ICountdown } from '@/interfaces/ICountdown';
import { cacheHttp, getBearerToken, setBearerToken } from '@/request/index';
import { ConfiguracaoGeral } from '@/models/ConfiguracaoGeral';
import { IParametros } from '@/interfaces/IParametros';
import store from '@/store/index';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/',
        name: 'Inicio',
        component: Inicio
      },
      {
        path: '/cadastro',
        name: 'Cadastro',
        component: Cadastro
      },
      {
        path: '/callCenter/:id?',
        name: 'CallCenter',
        component: Inicio
      },
      {
        path: '/cadastroantecipado',
        name: 'CadastroAntecipado',
        component: CadastroAntecipado
      },
      {
        path: 'consultaunidades',
        name: 'ConsultaUnidades',
        component: ConsultaUnidades
      },
      {
        path: 'transferencia',
        name: 'Transferencia',
        component: Transferencia
      },
      {
        path: 'fila',
        name: 'ConsultaFilaDeEspera',
        component: ConsultaFilaDeEspera
      },
      {
        path: '/protocolos',
        name: 'Protocolos',
        component: Protocolos
      }
    ]
  },
  {
    path: '/aguarde',
    name: 'Aguarde',
    component: Countdown,
    props: true
  },
  {
    path: '*',
    redirect: {
      name: 'Inicio'
    },
  },
  {
    path: '/error',
    name: 'Error',
    component: Error
  }
]

const router = new VueRouter({ routes });

router.beforeEach(async (to, from, next) => {
  const horaEmMS = 3600000;
  const quantidadeDeHorasParaAtualizacaoDoToken = 6 * horaEmMS;
  if (!getBearerToken() || 
      (store.state.dataHoraToken == new Date(0) || store.state.dataHoraToken < (new Date(new Date().getTime() - (quantidadeDeHorasParaAtualizacaoDoToken))))) 
  {
    await cacheHttp.get('configuracao')
      .then(res => {
        if (res.data.sucesso === true && res.data.atualizar === true) {
          const solicitante = store.state.solicitante;
          const atendimento = store.state.atendimento;
          const usuarioId = localStorageService.getItem('usuarioid');
    
          const protocolosAntesDeLimparASessao = localStorageService.obtenhaProtocolos();
          window.localStorage.clear();
          protocolosAntesDeLimparASessao.forEach(p => {
            localStorageService.adicioneProtocolo(p);
          });
    
          const dados = res.data;
          const configuracao: ConfiguracaoGeral = dados.configuracao;

          setBearerToken(dados.token, dados.tokenNotificacao);
          localStorageService.setConfiguracaogeral(configuracao);
          
          store.commit('setTipos', configuracao.tipos);
          if (solicitante.nome) 
          {
            store.commit('setSolicitante', solicitante);
            store.commit('setAtendimento', atendimento);
          }

          if(usuarioId && store.state.ehPeloCallCenter){
            localStorageService.setUsuarioId(usuarioId);
          }

          if (configuracao.brasao) {
            const favicon = window.document.querySelectorAll('[rel="icon"]');
            if (favicon) {
              favicon[0].setAttribute('href', configuracao.brasao);
            }
          }

        } else if (res.data.sucesso === false) {
          store.commit('setError', res.data.mensagem);
          next({name: 'Error'});
        }
      })
      .catch(() => {
        store.commit('setError', 'Ocorreu um erro ao buscar as configurações. Por favor aguarde alguns minutos e tente novamente.');
        next({name: 'Error'});
      }); 
  }

  try {
    switch (to.name) {
      case 'Aguarde': {
        const stringIni = localStorage.getItem('dtInicio');
        const stringFim = localStorage.getItem('dtFim');
        if (!stringIni || !stringFim) {
          next({ name: 'Inicio' });
        } else {
          next();
        }
        break;
      }

      case 'Inicio': {
        let usuarioId = localStorageService.getItem('usuarioid');
        if (!(possuiValor(usuarioId) && usuarioId != 'null')) {
          usuarioId = '0';
        }

        localStorageService.limpePropriedades();
        localStorageService.limpeDadosDoCadastroAntecipado();
        await cacheHttp.get(`countdowndto/${usuarioId}`)
          .then(response => {
            const result = response.data;
            if (result.sucesso) {
              const countdown: ICountdown = result.dados;
              if (countdown.informaAguarde) {
                localStorageService.setDataInicio(countdown.inicio);
                localStorageService.setDataFim(countdown.fim);
                localStorageService.setMensagemAguarde(countdown.mensagemAguarde);
                next({ name: 'Aguarde' });
              } else {
                next();
              }
            } else if (result.alerta) {
              store.commit('setError', result.mensagem);
              next({name: 'Error'});
            }
          })
          .catch(err => {
            const message = err.response.data;
            store.commit('setError', message);
            next({name: 'Error'})
          });
        break;
      }

      case 'Cadastro': {
        const id = localStorageService.obtenhaPropriedades().id as number | null;
        if (id == 0 || id == null || id == undefined) {
          next({name: 'Inicio'});
          return;
        }

        if(store && store.state.ehPeloCallCenter && !store.state.solicitante.nome){
          store.commit('setError', 'Não foi possível obter o operador logado, por favor atualize a página');
          next({name: 'Error'});
          return;
        }
  
        let usuarioId = localStorageService.getItem('usuarioid');
        if (!(possuiValor(usuarioId) && usuarioId != 'null')) {
          usuarioId = '0';
        }

        await cacheHttp.get(`cadastromatricula/${id}/${usuarioId}`)
          .then(response => {
            const result = response.data;
            const ehPreMatriculaPeloCallCenter = store.state.ehPeloCallCenter;
    
            if (result.sucesso) {
              const parametros: IParametros = result.dados;

              if(ehPreMatriculaPeloCallCenter && !parametros.utilizaCallCenter){
                alertWarning('O Call Center não está habilitado para esta Pré Matrícula');
                localStorageService.limpePropriedades();
                localStorageService.limpeDadosDoCadastroAntecipado();
                return;
              }

              store.commit('setParametros', parametros);

              // Tratativa para exibir as informações necessárias caso o fluxo seja pelo Call Center
              if(ehPreMatriculaPeloCallCenter)
              {              
                const camposEDescricoes = 
                {
                  'anexarComprovanteResidencia': 'Comprovante de residência',
                  'cartaoSusCandidato': 'Cartão SUS do candidato',
                  'cpfCandidato': 'CPF do candidato',
                  'cpfResponsaveis': 'CPF dos responsáveis',
                  'informarCelularPrincipal': 'Telefone Principal',
                  'informarCelularSecundario': 'Telefone Secundário',
                  'informarIrmaoNaRede': 'Irmão na rede',
                  'informarJaEstuda': 'Se já é estudante',
                  'informarNumeroNis': 'Número NIS',
                  'informarRenda': 'Renda',
                  'informarTempoMoradia': 'Tempo de moradia'
                };
                let camposObrigatorios = "<div style='text-align: left;'><li>Nome</li><li>Sexo</li><li>Data de Nascimento</li><li>Endereço</li>";
                Object.keys(parametros).forEach(key => {
                  if(parametros[key] === 2 && camposEDescricoes[key]){ 
                    camposObrigatorios = camposObrigatorios + "<li>" + camposEDescricoes[key] + "</li>";
                  }
                });
                camposObrigatorios = camposObrigatorios + "</div>";
                alertInfo('Informações do candidato necessárias para prosseguir com o cadastro: ' + camposObrigatorios);
              }
              next();
            } else if (result.alerta) {
              alertWarning(result.mensagem);
              next({name: 'Inicio'});
            } else {
              throw result.mensagem;
            }
          })
          .catch(() => {
            next({name: 'Inicio'});
          });
        break;
      }
      
      case 'CadastroAntecipado': {
        await cadastroAntecipadoService.obtenhaDadosDoCadastroAntecipado()
        .then(res => {
          localStorageService.limpeDadosDoCadastroAntecipado();
          localStorageService.atualizeDadosDoCadastroAntecipado(res.data);
          next();
        })
        .catch(err => {
          alertWarning(err.response.data);
          next({ name: 'Inicio' });
        });
        break;
      }
      
      case 'Error': {
        if (app === undefined || store === undefined || !store.state.error) {
          next({name: 'Inicio'});
        } else {
          next();
        }
        break;
      }
      
      case 'CallCenter': {
        store.commit('setFlagCallCenter', true);
        localStorageService.setUsuarioId(to.params.id);
        next({ name: 'Inicio' });
        break;
      }

      case 'Transferencia': {
        const parametros = localStorageService.obtenhaConfiguracaoGeral().parametrosSolicitacaoDeTransferencia;
        if (!parametros.habilitado) {
          alertWarning('A solicitação de transferência não está habilitada.');
          next({ name: 'Inicio' })
        }

        const usuarioId = localStorageService.getItem('usuarioid');
        if ((possuiValor(usuarioId) && usuarioId != 'null') && !parametros.habilitaCallCenter) {
          alertWarning('A configuração não permite efetuar a solicitação de transferência através do Call Center.');
          next({ name: 'Inicio' })
        }

        next();
        break;
      }

      default:
        next();
        break;
    }

  }
  catch (err) {
    store.commit('setError', 'Ocorreu um erro inesperado. Contate o administrador do sistema.');
    next({name: 'Error'});
  }
});

export default router;