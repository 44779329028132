import { ConfigMatriculaDTO } from "@/dtos/ConfigMatriculaDTO";
import { ParametrosSolicitacaoDeTransferenciaDTO } from "@/dtos/ParametrosSolicitacaoDeTransferenciaDTO";

export class ConfiguracaoGeral {
    agruparBotoesDeCadastro!: boolean;
    textoDoBotaoParaCadastroAntecipado!: string;
    textoDoBotaoParaPreMatricula!: string;
    token!: string;
    corPrimaria!: string;
    corSecundaria!: string;
    titulo!: string;
    videoDeAjuda!: string;
    cadastroAntecipadoHabilitado!: boolean;
    textoAuxiliar!: string;
    tipos!: ConfigMatriculaDTO[];
    brasao!: string;
    imageBase64!: string;
    termoSolicitacaoDeTransferencia!: string;
    textoAnoEscolarIncorreto!: string;
    habilitaConsultaDeFila!: boolean;

    parametrosSolicitacaoDeTransferencia!: ParametrosSolicitacaoDeTransferenciaDTO;
}