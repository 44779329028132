import { Component, Vue } from "vue-property-decorator";
import localStorageService from '@/services/localStorageService';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
import { cacheHttp, getUrlCentralDeNotificacoes, notificacao } from "@/request";
import { alertInfo, confirm, alertWarning, confirmSucess, showLoading, closeLoading } from "@/utils/functions";
import candidatoService from "@/services/candidatoService";
import { ProcessamentoProtocolo } from "@/models/ProcessamentoProtocolo";
import { http } from '@/request/index';
import { eTipoPesquisaProtocolo } from "@/enums/eTipoPesquisaProtocolo";
import { IResultApi } from "@/interfaces/IResultApi";
import { Propriedades } from "@/models/Propriedades";
import { ContatoMatriculaDTO } from "@/dtos/ContatoMatriculaDTO";

const reconexao = 60000;

@Component
export default class Aguarde extends Vue {
    hubConnectionTimeoutId: number | null = null;

    protocolos = Array<ProcessamentoProtocolo>();
    corPrimaria = '';
    corSecundaria = '';

    dialog = false;
    loading = false;
    protocoloPesquisa = '';

    carregandoContato = true;
    possuiContato = false;
    contatos = new Array<ContatoMatriculaDTO>();

    acaoParaEntrarNoGrupo = '';
    acaoDeSucesso = '';
    acaoDeErro = '';
  
    connection: HubConnection = new HubConnectionBuilder()
        .withUrl(getUrlCentralDeNotificacoes())
        .configureLogging(LogLevel.None)
        .build();
  
    created() {
      this.protocolos = localStorageService.obtenhaProtocolos();
      this.corPrimaria = localStorageService.obtenhaConfiguracaoGeral().corPrimaria;
      this.corSecundaria = localStorageService.obtenhaConfiguracaoGeral().corSecundaria;
      
      this.carregueDadosDeContato();
      this.assineVerificarProtocolosJaFinalizados();
      this.inicieConexaoComSignalR();
    }

    inicieConexaoComSignalR() {
      // Obtém o nome de cada função ser invocada quando um processo for finalizado.
      notificacao.get('/v1/FilaMatriculaOnline/acoes')
        .then(res => {
          const data = res.data;
          this.startConnection();

          this.connection.onclose(() => {
            if (!this.hubConnectionTimeoutId) {
              this.hubConnectionTimeoutId = setTimeout(() => { this.startConnection(); }, reconexao);
            }
          });

          this.connection.on(data.acaoQuandoSucesso, this.candidatoPersistidoComSucesso);
          this.connection.on(data.acaoQuandoErro, this.candidatoPersistidoComErro);

          this.acaoParaEntrarNoGrupo = data.acaoParaEntrarNoGrupoDeProtocolo;
          this.acaoDeSucesso = data.acaoQuandoSucesso;
          this.acaoDeErro = data.acaoQuandoErro;
        })
        .catch(() => {
          setTimeout(() => {
            this.inicieConexaoComSignalR();
          }, reconexao);
        });
    }
  
    startConnection() {
      this.hubConnectionTimeoutId = null;
      this.connection.start().then(() => { this.entrarNoGrupoDeFilaDeEspera(this.protocolos.map(p => { return p.Protocolo; })); });
    }

    entrarNoGrupoDeFilaDeEspera(protocolos: string[]) {
      if (this.acaoParaEntrarNoGrupo) {
        this.connection.send(this.acaoParaEntrarNoGrupo, protocolos);
      }
    }
  
    candidatoPersistidoComSucesso(protocoloSucesso: string, mensagemDeSucesso: string) {
      const protocoloNaLista = this.protocolos.filter(c => c.Protocolo == protocoloSucesso);
      if (protocoloNaLista.length > 0) {
        protocoloNaLista[0].Sucesso = true;
        protocoloNaLista[0].Mensagem = mensagemDeSucesso;
  
        this.exibaAlertaDeProtocoloProcessado(protocoloSucesso, true, mensagemDeSucesso, null);
      }
    }
  
    candidatoPersistidoComErro(protocoloErro: string, mensagemDeErro: string, preMatriculaId: number, militar: boolean, infantil: boolean) {
      const protocoloNaLista = this.protocolos.filter(c => c.Protocolo == protocoloErro);
      if (protocoloNaLista.length > 0) {
        protocoloNaLista[0].Sucesso = false;
        protocoloNaLista[0].Mensagem = mensagemDeErro;
        this.exibaAlertaDeProtocoloProcessado(protocoloErro, 
          false, 
          mensagemDeErro, 
          new Propriedades(preMatriculaId, militar, infantil, protocoloErro));
      }
    }
  
    beforeDestroy() {
      this.connection.off(this.acaoDeSucesso);
      this.connection.off(this.acaoDeErro);
    }
  
    async baixarProtocolo(protocolo: string) {
      candidatoService.getProtocolo(eTipoPesquisaProtocolo.MatriculaOnline, protocolo);
    }
  
    async assineVerificarProtocolosJaFinalizados() {
        const protocolosEmProcessamento = this.protocolos;
        if (protocolosEmProcessamento.length > 0) {
            let queryProtocolo = '';
            protocolosEmProcessamento.forEach(item => {
              queryProtocolo += `protocolos=${item.Protocolo}&`;
            });
            await http.get<IResultApi>(`/protocolo/filadeespera?${queryProtocolo}`)
              .then(res => {
                  const result = res.data;
                  if (result.sucesso && result.dados.length > 0) {
                    result.dados.forEach(item => {
                          const protocoloEmProcessamento = this.protocolos.filter(c => c.Protocolo == item.protocolo);
                          if (protocoloEmProcessamento.length > 0) {
                              protocoloEmProcessamento[0].Sucesso = item.sucesso;
                              protocoloEmProcessamento[0].Mensagem = item.mensagem;

                              // Itens processados há mais de 48h serão removidos da lista
                              if (item.remover) {
                                localStorageService.removaProtocolo(item.protocolo);
                              }
                          }
                      });
                  }
              });

            setTimeout(this.assineVerificarProtocolosJaFinalizados, reconexao);
        }
    }

    obtenhaClasseDoBotao(protocolo: ProcessamentoProtocolo) {
      return {
        'btn_protocolo_processamento': true,
        'lista-protocolo': true,
        'btn_sucesso': protocolo.Sucesso == true,
        'btn_falha': protocolo.Sucesso == false
      };
    }

    // Ações do modal de pesquisa
    openModal(): void {
      this.dialog = true;
    }
  
    obtenhaCorPrimaria(){
      return localStorageService.obtenhaConfiguracaoGeral().corPrimaria;
    }
  
    async buscarProtocolo() {
      if (!this.protocoloPesquisa) {
        alertInfo('Informe algum dos dados citados para pesquisar.');
        return;
      }

      let situacaoProtocolo;
      await http.get<IResultApi>('/protocolo/buscarfiladeespera?pesquisa=' + this.protocoloPesquisa)
        .then(res => {
          if (res.data.sucesso) {
            if (res.data.dados) {
              situacaoProtocolo = res.data.dados;
            } else {
              alertInfo('Não foi encontrado nenhum registro na fila de processamento ou candidato para os dados informados.');
            }
          }
        })
        .catch(() => {
          alertWarning('Ocorreu um erro inesperado ao tentar buscar os dados do protocolo. Aguarde alguns minutos e tente novamente.');
        });
      
      if (situacaoProtocolo) {
        const protocolosJaAdicionados = localStorageService.obtenhaProtocolos();
        if (protocolosJaAdicionados.filter(p => p.Protocolo == situacaoProtocolo.protocolo).length > 0) {
          const protocolo = protocolosJaAdicionados.filter(p => p.Protocolo == situacaoProtocolo.protocolo)[0];
          protocolo.Sucesso = situacaoProtocolo.sucesso;
          protocolo.Mensagem = situacaoProtocolo.mensagem;
        } else {
          if (situacaoProtocolo.sucesso == null) {
            this.entrarNoGrupoDeFilaDeEspera([situacaoProtocolo.protocolo]);
          }

          const item = new ProcessamentoProtocolo(situacaoProtocolo.protocolo, situacaoProtocolo.sucesso, situacaoProtocolo.mensagem, situacaoProtocolo.dataCadastro);
          localStorageService.adicioneProtocolo(item);
          this.protocolos.push(item);

          if (situacaoProtocolo.sucesso != null) {
            this.clickProtocolo(situacaoProtocolo.protocolo);
          }
        }
      }
      this.dialog = false;
      this.protocoloPesquisa = '';
    }

    exibaAlertaDeProtocoloProcessado(protocolo: string, sucesso: boolean, mensagem: string, propriedadesDeCadastro: Propriedades | null) {
      if (sucesso) {
        confirmSucess(
          "Cadastro processado!",
          mensagem
        ).then((result) => {
          if (result.isConfirmed) {
            candidatoService.getProtocolo(eTipoPesquisaProtocolo.MatriculaOnline, protocolo);
          }
        });
      } else {
        confirm(
          "Erro ao processar cadastro!",
          mensagem
        ).then((result) => {
          if (result.isConfirmed) {
            localStorageService.atualizePropriedades(propriedadesDeCadastro!);
            this.$router.push('/cadastro');
          }
        });
      }
    }

    async carregueDadosDeContato() {
      await cacheHttp.get<IResultApi>(`/dadosdecontato`)
        .then((res) => {
          const data = res.data;
          if (data.sucesso) {
            data.dados.forEach(item => {
              this.contatos.push(new ContatoMatriculaDTO(item.descricaoMatricula, item.email, item.telefone));
            });
            this.carregandoContato = false;
            this.possuiContato = this.contatos.length > 0;
          } else {
            alertWarning(data.mensagem);
          }
        });
    }

    async clickProtocolo(protocolo: string) {
      const protocoloLocalStorage = this.protocolos.filter(c => c.Protocolo == protocolo);
      if (protocoloLocalStorage.length == 0) {
        alertWarning('Protocolo não encontrado. Recarregue a página e tente novamente.');
        return;
      }

      const protocoloEmProcessamento = protocoloLocalStorage[0];
      if (protocoloEmProcessamento.Sucesso == null) {
        alertInfo('Esse cadastro ainda está na fila de processamento. Aguarde mais um pouco.');
        return;
      } 
      else if (protocoloEmProcessamento.Sucesso == true) {
        this.baixarProtocolo(protocolo);
      }
      else {
        const continuar = `<br><br>Deseja recuperar os dados informados no cadastro e tentar de novo?`;
        const mensagem = `${protocoloEmProcessamento.Mensagem}${continuar}` || `Ocorreu um erro inesperado ao obter a situação desse protocolo. Aguarde alguns minutos e tente novamente.${continuar}`;

        confirm(
          "Erro ao processar cadastro!",
          mensagem
        ).then((result) => {
          if (result.isConfirmed) {
            showLoading('Obtendo dados do candidato...');
            setTimeout(() => {
              http.get<IResultApi>(`/propriedadesmatricula/${protocolo}`)
              .then(res => {
                closeLoading();
                if (res.data.sucesso) {
                  const data = res.data.dados;
                  localStorageService.atualizePropriedades(new Propriedades(data.id, data.ehMilitar, data.ehInfantil, protocolo));
                  this.$router.push('/cadastro');      
                } else {
                  alertWarning('Ocorreu um erro inesperado ao obter os dados para tentar efetuar o cadastro novamente. Aguarde alguns minutos e tente novamente.');
                }
              })
              .catch(err => {
                closeLoading();
              });
            }, 1000);
          }
        });
      }
    }
}