
import { CodigoDescricaoDTO } from "@/dtos/CodigoDescricaoDTO";
import { Documento } from "@/models/Documento";
import cadastroAntecipadoService from "@/services/cadastroAntecipadoService";
import { closeLoading, showLoading } from "@/utils/functions";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ModalResponsavel extends Vue {

  dialog = false;
  valid = false;
  tiposDeDocumento: CodigoDescricaoDTO[] = new Array<CodigoDescricaoDTO>();
  tiposDeDocumentoParaSelecionar = new Array<string>();
  documento = new Documento();

  validate = {
    descricao: [
      (v: string) => (!!v && !!v.trim()) || "A descrição é obrigatória",
      (v: string) => v.length <= 120 || "A descrição deve conter até 120 caracteres.",
    ],
    arquivo: [
      (v: File) => !!v || "O arquivo é obrigatório."
    ],
    comentarios: [
      (v: string) => !!v || v.length <= 500 || 'Os comentários devem conter até 500 caracteres.'
    ]
  }

  async openModal(): Promise<void> {
    this.valid = false;
    this.documento = new Documento();

    showLoading("Carregando informações para anexar documento...");
    
    setTimeout(async () => {
      await cadastroAntecipadoService.tiposDeDocumento()
      .then(res => {
        res.data.forEach(item => {
          this.tiposDeDocumento.push(item);
          this.tiposDeDocumentoParaSelecionar.push(item.descricao);
        });
      });
      
      this.dialog = true;
      closeLoading();
    }, 1000);
  }
  
  salvar(): void {
    const validacao = (this.$refs.form as Vue & {
      validate: () => boolean;
    }).validate();

    if (validacao) {
      const descricaoSelecionada = this.tiposDeDocumento.filter(c => c.descricao == this.documento.descricao);
      if (descricaoSelecionada && descricaoSelecionada.length > 0) {
        this.documento.documentoId = descricaoSelecionada[0].codigo;
      }

      this.$store.commit("addDocumento", this.documento);
      this.dialog = false;
    }
  }
}
