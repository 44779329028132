
import { Component, Vue } from "vue-property-decorator";
import AppBar from "@/components/home/AppBar.vue";
import Footer from "@/components/home/Footer.vue";
import { eTipoFinalizacaoAtendimento } from "@/enums/eTipoFinalizacaoAtendimento";
import localStorageService from "@/services/localStorageService";
import callCenterService from "@/services/callCenterService";
import ModalSolicitante from "@/components/ModalSolicitante.vue";
import { Atendimento } from "@/models/Atendimento";

@Component({
  components: {
    AppBar,
    Footer,
    ModalSolicitante
  },
})
export default class Home extends Vue {
  usuarioid: string | null = localStorage.getItem('usuarioid');

  async finalizeAtendimentoNoUnload() {
    this.finalizeAtendimento(eTipoFinalizacaoAtendimento.AutomaticamentePeloNavegador);
  }

  existeAtendimento(): boolean{
    const atendimento: Atendimento = this.$store.state.atendimento;    
    return !!atendimento.id;
  }

  async finalizeAtendimento(tipoFinalizacao: eTipoFinalizacaoAtendimento){
    const atendimento = this.$store.state.atendimento;
    const usuarioId = localStorageService.getItem('usuarioid') || '';
    if(atendimento.id){
      callCenterService.finalizeAtendimento(atendimento.id, tipoFinalizacao);
      this.$store.commit("clearAtendimento");
    }
    if(tipoFinalizacao != eTipoFinalizacaoAtendimento.AutomaticamentePeloNavegador){
      this.$store.commit('setFlagCallCenter', true);
      localStorageService.setUsuarioId(usuarioId);
      this.showModalSolicitanteVazio();
    }
  }

  showModalSolicitanteVazio(){
    (this.$refs.modalSolicitante as ModalSolicitante & {
        openModal: () => void;
      }).openModalVazio();
  }
}
