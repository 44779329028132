
import { Component, Vue } from "vue-property-decorator";
import PreCadastro from "../components/PreCadastro.vue";

@Component({
  components: {
    PreCadastro
  },
})
export default class CadastroAntecipado extends Vue {
  
}

