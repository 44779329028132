export class Propriedades{
    public id!: number | null;
    public ehMilitar!: boolean | null;
    public ehInfantil!: boolean | null;
    public protocoloComErro!: string | null;

    constructor(id: number | null, ehMilitar: boolean | null, ehInfantil: boolean | null, protocoloComErro: string | null) {
        this.id = id;
        this.ehMilitar = ehMilitar;
        this.ehInfantil = ehInfantil;
        this.protocoloComErro = protocoloComErro;
    }
}