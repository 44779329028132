import { CodigoDescricaoDTO } from '@/dtos/CodigoDescricaoDTO';
import { ConfiguracaoSeriePreMatriculaDTO } from '@/dtos/ConfiguracaoSeriePreMatriculaDTO';
import { eTurno } from '@/enums/eTurno';
import { http } from '@/request/index';

export function buscaSerie(dataNascimento: Date | null, preMatriculaId: number) {
  const url = `series`;

  const parametros = {
    dataNascimento,
    preMatriculaId
  };
  
  return http.post<ConfiguracaoSeriePreMatriculaDTO[]>(url, parametros);
}

export function obtenhaQuantidadeDevagas(preMatriculaId: number, escolaId: number, serieId: number | null, turno: eTurno) {
  const url = `series/vagas/${preMatriculaId}/${escolaId}/${serieId}/${turno}`;

  return http.get<number>(url);
}

export function obtenhaSeriesAtivas() {
  const url = 'series/todas';
  return http.get<CodigoDescricaoDTO[]>(url);
}

export function carregarSeries(preMatriculaId: number, escolaId: number, turno: eTurno | null) {
  const url = `series/porescolaematricula/${preMatriculaId}/${escolaId}/${turno}`;
  return http.get<CodigoDescricaoDTO[]>(url);
}