export class ProcessamentoProtocolo {
    public Protocolo!: string;
    public Sucesso!: boolean | null;
    public Mensagem!: string | null;
    public DataCadastro!: string;

    constructor(protocolo: string, sucesso: boolean | null, mensagem: string | null, dataCadastro: string) {
        this.Protocolo = protocolo;
        this.Sucesso = sucesso;
        this.Mensagem = mensagem;
        this.DataCadastro = dataCadastro;
    }
}