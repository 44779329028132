import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueTheMask from 'vue-the-mask';
import VueCurrencyInput from 'vue-currency-input';
import VueCountdownTimer from 'vuejs-countdown-timer';
import moment from 'vue-moment';
import store from '@/store';
import '@/style/site.css';
import '@/style/switch-button.css';

const pluginOptions = {
  globalOptions: {
    currency: 'BRL',
    locale: 'pt',
    autoDecimalMode: true,
    allowNegative: false
  }
};

Vue.use(moment);
Vue.use(VueCountdownTimer);
Vue.use(VueCurrencyInput, pluginOptions);
Vue.use(VueTheMask);

Vue.config.productionTip = false;

const app = new Vue({
  router,
  vuetify,
  store: store,
  render: h => h(App)
}).$mount('#app');

export default app;
