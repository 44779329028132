export class ContatoMatriculaDTO {
    public descricaoMatricula!: string;
    public email: string | null;
    public telefone: string | null;

    constructor (descricao: string, email: string | null, telefone: string | null) {
        this.descricaoMatricula = descricao;
        this.email = email;
        this.telefone = telefone;
    }
}